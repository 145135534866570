<!--
   Created by Terence on 2024/3/7 - 17:13
-->
<template>
  <div class="binfo">
    <div class="binfo-imgs">
      <img
        v-if="detailData?.watermaskImages?.[0]?.url"
        class="binfo-imgs-item"
        oncontextmenu="return false;"
        :src="detailData?.watermaskImages?.[0]?.url + '!mfit_w750_h750_jpg'"
        alt=""
      />
      <div class="showact" @click="handleToTokenPage">
        查看作品
        <ArrowRight :style="{ marginLeft: '0.5333333333333333rem' }" type="white" />
      </div>
      <!--      <token-number-->
      <!--        @handleToOwnerList="handleToOwnerList"-->
      <!--        :index="detailData.tokenIndex.toString()"-->
      <!--        v-if="-->
      <!--          isElementVisible && detailData.tokenIndex && detailData.type === 'SFT'-->
      <!--        "-->
      <!--      />-->
    </div>
    <div class="token-preview-section-wrp">
      <TokenPreviewSection @onInvokeApp="onInvokeApp" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import TokenPreviewSection from "./TokenPreviewSection";
// import TokenNumber from "./TokenNumber.vue";
import { useElementVisibility } from "@/hooks/useElementVisibility";
import ArrowRight from "@/components/ArrowRight/index.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const detailData = inject("detailData");
const bigTokenNoRef = inject("bigTokenNoRef");
const tokenId = inject("tokenId");

const emit = defineEmits(["onInvokeApp"]);

const onInvokeApp = () => {
  emit("onInvokeApp");
};

const handleToTokenPage = () => {
  router.push(`/token-detail?tokenId=${tokenId.value}`);
};

// const bigTokenNoRef = ref();
const { isElementVisible } = useElementVisibility(bigTokenNoRef);
</script>

<style lang="scss" scoped>
.binfo {
  position: relative;
  padding-bottom: 32rpx;
  border-radius: 32rpx;
  background: #fff;
  box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  &-imgs {
    position: relative;
    margin-bottom: 32rpx;
    &-item {
      width: 100%;
      display: block;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }

  .showact {
    position: absolute;
    bottom: 16rpx;
    right: 30rpx;
    display: flex;
    padding: 6rpx 20rpx;
    justify-content: center;
    align-items: center;
    color: var(--v-10-main-white-09, #fff);
    font-size: 24rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 36rpx; /* 150% */
    border-radius: 8rpx;
    background: var(---button_info_color, rgba(0, 0, 0, 0.5));
  }

  .token-preview-section-wrp {
    padding: 0 32rpx;
  }
}
</style>
