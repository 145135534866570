<template>
  <div class="binfo-wrp">
    <div @click="handleGo2Field" class="field u-flex-c-sb">
      <div class="field-name H6">{{ field.name }}</div>
      <div class="auctionsCount u-flex-c">
        <div class="B6">{{ field.auctionsCount }}</div>
        <div class="B7">件拍品</div>
        <DuIcon name="arrow_heavy_right" size='2.1333333333333333rem' />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

import { DuIcon } from "dangoui";
const router = useRouter();
const props = defineProps({
  field: {
    type: Object,
    default: () => null,
  },
});
function handleGo2Field() {
  router.push(`/auction-field-list?fieldId=${props.field.id}`);
}
</script>

<style lang="scss">
.field {
  margin-top: 48rpx;
  height: 108rpx;
  border-radius: 24rpx;
  background: linear-gradient(90deg, #f1e6ff 0%, #fff 49.86%);

  /* 💙 y:4 ; Blur=20（NFT 卡片投影） */
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 0 32rpx;
  .field-name {
    color: rgba(0, 0, 0, 0.64);
  }
  .auctionsCount {
    gap: 4rpx;
    .B7 {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
