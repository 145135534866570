/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
import { rpNft, rpApi } from "@/http";

interface OrderData {
  orderId: string;
}

export default {
  checkWasParticipate(params: any) {
    return rpNft.post("/trade/auction/check-participate", params);
  },
  goParticipate(params: any) {
    return rpNft.post("/trade/auction/participate", params);
  },
  // goBid(params: any) {
  //   return rpNft.post("/trade/auction/bid", params);
  // },
  // 使用c2c bid
  goBid(params: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/bid/increase", params);
  },
  // 生成保证金order
  createDeposit(params: any) {
    return rpNft.post("/trade/auction/participate", params);
  },
  // 生成批发||一口价order
  createProductOrder(params: any) {
    return rpNft.post<OrderData>("/trade/buy-sale-order", params);
  },
  // 竞价信息
  getBidInfo(params: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/bid/my", params, {
      isErrorDefaultTip: false,
    });
  },

  availablePrice(data: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/bid/available-price", data);
  },

  getDepositProportion(data: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/bid/deposit-proportion", data);
  },

  getMyBidInfo(data: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/bid/my", data);
  },
};
