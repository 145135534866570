<!--
   Created by Terence on 2024/3/7 - 17:13
-->
<template>
  <div class="pinfo">
    <div class="pinfo-values" v-if="isBuyoutType">
      <div class="pinfo-values-left">
        <span class="price">
          <span class="price-suffix">¥</span>
          <span class="price-value">
            {{ buyoutDetailData.product?.price }}
          </span>
        </span>
        <span class="count">
          <span class="count-label">库存</span>
          <span class="count-value">
            &nbsp;{{ buyoutDetailData.product?.stock }}
          </span>
        </span>
      </div>
      <div class="pinfo-values-right">
        <span class="count">
          <span class="count-label">卖出</span>
          <span class="count-value">
            &nbsp;{{ buyoutDetailData.otherDetail?.sellCount }}
          </span>
        </span>
        <span class="splitline" />
        <span class="count">
          <span class="count-label">收藏</span>
          <span class="count-value">
            &nbsp;{{ buyoutDetailData.otherDetail?.favoriteCount }}
          </span>
        </span>
      </div>
    </div>
    <div class="pinfo-values" v-else>
      <div class="pinfo-values-left">
        {{ +auctionDetailData.currentBidsCount ? "最高" : "起拍" }}&nbsp;
        <span class="price">
          <span class="price-suffix">¥</span>
          <span class="price-value">
            {{
              auctionDetailData?.currentPrice ||
              auctionDetailData?.startingPrice
            }}
          </span>
        </span>
      </div>
      <div class="pinfo-values-right">
        <span class="count">
          <span class="count-label">订阅</span>
          <span class="count-value">
            &nbsp;{{ auctionDetailData.followCount }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import TokenPreviewSection from "./TokenPreviewSection";
// import TokenNumber from "./TokenNumber.vue";
import { useElementVisibility } from "@/hooks/useElementVisibility";

const detailData = inject("detailData");
const buyoutDetailData = inject("buyoutDetailData");
const auctionDetailData = inject("auctionDetailData");
// const tradeType = inject("tradeType");
const isBuyoutType = inject("isBuyoutType");
const bigTokenNoRef = inject("bigTokenNoRef");

// const bigTokenNoRef = ref();
const { isElementVisible } = useElementVisibility(bigTokenNoRef);
</script>

<style lang="scss" scoped>
.pinfo {
  //border-radius: 32rpx;
  //overflow: hidden;
  &-values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      .price {
        margin-right: 16rpx;
        display: flex;
        align-items: baseline;
        &-suffix {
          color: var(---color_third, #7247dc);
          font-family: "Roboto";
          font-size: 36rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 50rpx; /* 138.889% */
        }
        &-value {
          margin-left: 4rpx;
          color: var(---color_third, #7247dc);
          font-family: "Roboto";
          font-size: 48rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 64rpx; /* 133.333% */
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    .count {
      color: var(---text_third, rgba(0, 0, 0, 0.5));
      font-size: 24rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 36rpx; /* 150% */
      white-space: nowrap;
      &-label,
      &-value {
        display: inline-block;
      }
    }
  }
  &-desc {
    margin-top: 24rpx;
    &-value {
      color: var(---text_secondary, rgba(0, 0, 0, 0.7));
      font-size: 28rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 44rpx; /* 157.143% */
    }
  }

  .splitline {
    display: inline-block;
    margin: 0 16rpx;
    width: 2rpx;
    height: 24rpx;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
