<!--eslint-disable-->
<template>
  <div class="selling-info-popup">
    <van-popup
      v-model:show="modelValue"
      closeable
      position="bottom"
      lock-scroll
      close-icon="https://cdn.qiandaoapp.com/ued/d80d10918056cda4940aa8ccd6b01784.png"
      @close="handleClose"
      round
    >
      <div class="selling-info-popup-content">
        <div class="title">详情</div>
        <div class="infos">
          <div v-for="(item, index) in infos" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, inject } from "vue";
import { Popup as VanPopup, Button as VanButton } from "vant";
import PrePurchaseTips from "@/components/PrePurchaseTips";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  infos: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(["update:modelValue", "onConfirmed"]);

const prePurchaseConfig = inject("prePurchaseConfig");

const handleClose = () => {
  emit("update:modelValue", false);
};

const handleSubmit = () => {
  emit("onConfirmed");
};

onMounted(() => {
  //
});
</script>

<style lang="scss">
:root:root {
  .selling-info-popup {
    --van-popup-background: white;
  }
}
.selling-info-popup {
  &-content {
    //padding: 56rpx 30rpx 260rpx 30rpx;
    padding: 56rpx 30rpx 160rpx 30rpx;
    .title {
      color: var(---text_primary, #000);
      font-size: 40rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 52rpx; /* 130% */
    }
    .infos {
      margin-top: 32rpx;
      color: var(---text_secondary, rgba(0, 0, 0, 0.7));
      font-size: 32rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 48rpx; /* 150% */
    }
  }
}
</style>
