<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="author section">
    <!--      <div class="section-title">拥有者</div>-->
    <div class="author-content">
      <div class="author-content--top top">
        <img
          v-show="detailData.owner?.avatarUrl"
          class="top-avatar"
          :src="detailData.owner?.avatarUrl"
          alt=""
        />
        <div class="top-info">
          <div class="top-info--name">
            {{ detailData.owner?.displayName }}
          </div>
          <div @click="goToPolygon">
            {{ detailData.lastTrxTimeString }}
            拥有该作品
            <!--            <span class="author-content&#45;&#45;bottom&#45;&#45;item&#45;&#45;hash">{{-->
            <!--              detailData.lastTrxHash-->
            <!--                ? detailData.lastTrxHash?.slice(0, 6) + "..."-->
            <!--                : ""-->
            <!--            }}</span>-->
            <!--            <span class="author-content&#45;&#45;bottom&#45;&#45;item&#45;&#45;hash">{{-->
            <!--              detailData.lastTrxHash ? detailData.lastTrxHash?.slice(-6) : ""-->
            <!--            }}</span>-->
            <!--            <arrow-right />-->
          </div>
          <!--            <div class="top-info&#45;&#45;price">-->
          <!--              以最后成交价-->
          <!--              <span class="top-info&#45;&#45;price&#45;&#45;value">¥300</span> 购得该作品-->
          <!--            </div>-->
        </div>
      </div>

      <!--      <div class="batch-author" v-if="detailData.type === 'SFT'">-->
      <!--        &lt;!&ndash;      <div class="batch-author" v-if="true">&ndash;&gt;-->
      <!--        <div class="dash-line" />-->
      <!--        <div class="desc">-->
      <!--          <div class="desc-count">{{ detailData.ownerCount }}&nbsp;</div>-->
      <!--          <div class="desc-content">位拥有者同时拥有该作品</div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import ArrowRight from "@/components/ArrowRight";
import { Button as VanButton } from "vant";
import BidApi from "@/apis/bid";
import { REQUEST_OK } from "@/constant/network";

const props = defineProps({
  detailData: {
    type: Object,
    required: true,
  },
});

const goToPolygon = () => {
  window.open(`https://polygonscan.com/tx/${props.detailData.lastTrxHash}`);
};

const handleClickBtn = () => {
  alert("等待支付流程接通");
};
</script>

<style lang="scss">
.author {
  &-content {
    margin-top: 32rpx;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24rpx 24rpx 32rpx 24rpx;
    gap: 24rpx;
    background: #ffffff;
    box-shadow: 0 8rpx 40rpx rgba(0, 0, 0, 0.06);
    border-radius: 32rpx;
    &--top {
    }
    .top {
      display: flex;
      flex-direction: row;
      text-align: left;
      &-avatar {
        object-fit: cover;
        display: flex;
        width: 96rpx;
        height: 96rpx;
        border-radius: 100%;
        border: 1px solid rgba(12, 12, 12, 0.1);
      }
      &-info {
        display: flex;
        flex-direction: column;
        //align-items: center;
        justify-content: space-between;
        margin-left: 14rpx;
        padding: 4rpx 0;
        &--name {
          font-weight: 800;
          font-size: 32rpx;
          //line-height: 48rpx;
          letter-spacing: 0.01em;
          color: #000000;
        }
        &--price {
          margin-top: 4rpx;
          font-size: 20rpx;
          line-height: 22rpx;
          color: rgba(0, 0, 0, 0.5);
          &--value {
            font-weight: 900;
            color: #000000;
          }
        }
      }
    }
    &--bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--item {
        font-size: 20rpx;
        line-height: 22rpx;
        color: #000000;
        &--hahs {
          margin-right: 8rpx;
        }
      }
    }
    .batch-author {
      .dash-line {
        width: 626rpx;
        border-top: 2rpx dashed rgba(0, 0, 0, 0.12);
      }
      .desc {
        margin-top: 26rpx;
        display: flex;
        align-items: center;
        &-count {
          font-weight: 900;
          font-size: 24rpx;
          line-height: 36rpx;
          color: #000000;
        }
        &-content {
          font-weight: 400;
          font-size: 24rpx;
          line-height: 36rpx;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>

<!--v-model	min max step-->
