// useElementVisibility.js
import { Ref, ref, onMounted, onUnmounted } from "vue";

export function useElementVisibility(elementRef: Ref) {
  const isElementVisible = ref(false);

  const checkVisibility = () => {
    if (elementRef.value) {
      const rect = elementRef.value.getBoundingClientRect();
      isElementVisible.value = rect.top > 80;
    }
  };

  onMounted(() => {
    window.addEventListener("scroll", checkVisibility);
    checkVisibility(); // 初始化检查
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", checkVisibility);
  });

  return { isElementVisible };
}
