/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
import { rpNft, rpApi, rpPayment } from "@/http";

interface OrderData {
  orderId: string;
}

export default {
  getDepositProportion(data: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/bid/deposit-proportion", data);
  },
  createToUserDeposit(amount: number) {
    return rpPayment.post("/init/recharge", {
      amount,
      paymentSource: 5,
    });
  },
  getDepositWallet(data: any) {
    return rpApi.post("/funds-web/funds-page", data);
  },
};
