/**
 * Created by Terence on 2023/5/30 - 11:28
 * Description :
 */
import { ref } from "vue";
import { AUCTION_BTN_STAGE } from "@/constant/auction";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";
import {
  showConfirmDialog,
  showLoadingToast,
  showSuccessToast,
  closeToast,
  showFailToast,
} from "vant";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { emitter } from "@/utils/emitter.js";
import NftApi from "@/apis";
import { PRE_PAY_POPUP_TYPE } from "@/constant/payment";

export interface IBtnInfo {
  [key: string]: any; // TODO btnInfo
}

export interface IActionConfig {
  // [key: string]: any; // TODO IActionConfig
  confirmOrderId: string;
  tradeInfo: {
    tradeOrderId: string;
  };
  uiConfig: {
    btnInfo: IBtnInfo;
    price: number;
  };
}

export interface IInvokeBtnAction {
  actionConfig: IActionConfig;
  handleBid?: () => void;
  handlePay?: () => void;
  // handleConfirmForReceive?: (actionConfig: IActionConfig) => void;
  handleEvokeAppForConfirmSale?: () => void; // 未到保留价? 卖家确认是否出售
}

const handleEmitToRefreshToken = () => {
  emitter.emit("INIT_TOKEN_DETAIL");
};

export const handleConfirmForReceive = (actionConfig: IActionConfig) => {
  showConfirmDialog({
    title: "确认收稿后",
    message: `卖家将收到 ¥${actionConfig.uiConfig.price}`,
  })
    .then(async () => {
      showLoadingToast({
        message: "确认中...",
        forbidClick: true,
        duration: 0,
      });
      const res = await NftApi.orderConfirm({
        orderId: actionConfig.confirmOrderId,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (res.code === 0) {
        showSuccessToast("确认收稿成功");
        handleEmitToRefreshToken();
      } else {
        showFailToast("确认收稿失败");
        handleEmitToRefreshToken();
      }
      console.log("res!=!=!", res);
    })
    .catch(() => {
      showFailToast("确认收稿失败");
      handleEmitToRefreshToken();
      // on cancel
    });
};

export function useBtnAction() {
  // const isShowPrePurchasePopup = ref(false);
  const prePayCallback = ref<any>(null);
  const isShowRiskWarningPopup = ref(false);
  const invokeBtnAction = ({
    actionConfig,
    handleBid,
    handlePay,
    // handleConfirmForReceive,
    handleEvokeAppForConfirmSale,
  }: IInvokeBtnAction): any => {
    const {
      uiConfig: { btnInfo },
    } = actionConfig;
    console.log("btnInfo=!!!!!!----!!", btnInfo);

    if (btnInfo.stage === AUCTION_BTN_STAGE.ON_BIDDING) {
      handleBid && handleBid();
    } else if (btnInfo.stage === AUCTION_BTN_STAGE.ON_PAY) {
      handlePay && handlePay();
    } else if (btnInfo.stage === AUCTION_BTN_STAGE.WAIT_FOR_BUYER_CONFIRM) {
      // handleConfirmForReceive && handleConfirmForReceive(actionConfig);
      handleConfirmForReceive(actionConfig);
    } else if (
      btnInfo.stage === AUCTION_BTN_STAGE.INVOKE_APP_FOR_CONFIRM_SALE
    ) {
      handleEvokeAppForConfirmSale && handleEvokeAppForConfirmSale();
    } else if (btnInfo.stage === AUCTION_BTN_STAGE.WAIT_CONFIRM_IN_APP) {
      handleEvokeAppForConfirmSale && handleEvokeAppForConfirmSale();
    }
  };

  /*
   * 支付前拦截弹窗
   * ['风险确认拦截', ...]
   */
  const prePayAcl = ({
    type,
    callback,
  }: {
    type: string;
    callback: () => void;
  }) => {
    if (type === PRE_PAY_POPUP_TYPE.RISK_WARNING) {
      isShowRiskWarningPopup.value = true;
      prePayCallback.value = callback;
    }
  };

  const invokePrePayConfirmed = ({ type }: { type: string }) => {
    if (type === PRE_PAY_POPUP_TYPE.RISK_WARNING) {
      isShowRiskWarningPopup.value = false;
      console.log("prePayCallback===", prePayCallback);
      prePayCallback.value && prePayCallback.value();
    }
  };

  return {
    // isShowPrePurchasePopup,
    prePayAcl,
    invokeBtnAction,
    invokePrePayConfirmed,
    isShowRiskWarningPopup,
  };
}
