/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
/* eslint-disable */
import { rpNft, rpApi } from "@/http";

export default {
  getBuyoutProductDetail(params: any) {
    return rpApi.get("/c2c-web/v1/c2c/product-detail-page", params);
  },
  getAuctionProductDetail(params: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/auction/get-one", params);
  },
};
