/**
 * Created by Terence on 2023/2/15 - 21:15
 * Description :
 */
export const RESERVERPRICE_STAGE = {
  UNSET: 0,
  NOT_REACH: -1,
  EXCEEDED_PRICE: 1,
};

export const AUCTION_BTN_STAGE = {
  NO_QUALIFICATIONS: "NO_QUALIFICATIONS", // 一口价无资格
  ON_BIDDING: "ON_BIDDING",
  ON_PAY: "ON_PAY",
  INVOKE_APP_FOR_CONFIRM_SALE: "INVOKE_APP_FOR_CONFIRM_SALE",
  WAIT_FOR_SELLER_CONFIRM: "WAIT_FOR_SELLER_CONFIRM",
  WAIT_FOR_BUYER_CONFIRM: "WAIT_FOR_BUYER_CONFIRM",

  WAIT_CONFIRM_IN_APP: "WAIT_CONFIRM_IN_APP", // h5只根据待确认count来展示, 引导跳转至app
};

export const AUCTION_STAGE = {
  BIDDING: "BIDDING",
  WAIT_SELLER_CONFIRM: "WAIT_SELLER_CONFIRM",
  SETTLING: "SETTLING",
};

export const QIANDAO_AUCTION_STAGE = {
  AuctionStatus_None: "AuctionStatus_None", //占位符，搜索时候默认全部
  AuctionStatus_Bidding: "AuctionStatus_Bidding", //进拍中
  AuctionStatus_Flow: "AuctionStatus_Flow", //流盘
  AuctionStatus_Settlement: "AuctionStatus_Settlement", //结算中
  AuctionStatus_Close: "AuctionStatus_Close", //结算完成
  AuctionStatus_Flowed: "AuctionStatus_Flowed", // 流盘完成
  AuctionStatus_Prepare: "AuctionStatus_Prepare", // 预约拍卖
  AuctionStatus_WaitSellerConfirm: "AuctionStatus_WaitSellerConfirm", // 待卖家确认
};
