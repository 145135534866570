<!--eslint-disable-->
<template>
  <div class="risk-warning-popup">
    <van-popup
      v-model:show="modelValue"
      closeable
      position="bottom"
      lock-scroll
      close-icon="https://cdn.qiandaoapp.com/ued/d80d10918056cda4940aa8ccd6b01784.png"
      @close="handleClose"
      round
    >
      <pre-purchase-tips
        noPadding
        :currentStage="prePurchaseConfig.currentStage"
        :creatorId="prePurchaseConfig.creatorId"
        :watermaskImage="prePurchaseConfig.watermaskImage"
      />
      <div class="btn-wrp">
        <van-button type="primary" size="large" @click="handleSubmit">
          我已知晓，继续支付
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, inject } from "vue";
import { Popup as VanPopup, Button as VanButton } from "vant";
import PrePurchaseTips from "@/components/PrePurchaseTips";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "onConfirmed"]);

const prePurchaseConfig = inject("prePurchaseConfig");

const handleClose = () => {
  emit("update:modelValue", false);
};

const handleSubmit = () => {
  emit("onConfirmed");
};

onMounted(() => {
  //
});
</script>

<style lang="scss">
:root:root {
  .risk-warning-popup {
    --van-popup-background: white;
  }
}
.risk-warning-popup {
  .btn-wrp {
    padding: 16rpx 30rpx;
    margin-bottom: 60rpx;
  }
}
</style>
