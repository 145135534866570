<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="bid">
    <div class="top-wrp">
      <div class="bid-img">
        <van-image
          class="bid-img-content"
          fit="cover"
          :src="spDetailData?.attachments?.[0] + '!mfit_w750_h750_jpg'"
          alt=""
        />
      </div>
      <div class="bid-title">{{ spDetailData.name }}</div>
      <div v-if="wasParticipate" class="bid-joined-tips">
        你参与过本竞拍，继续更新出价
      </div>
      <div class="bid-stepper">
        <stepper
          contentType="text"
          :initValue="initCount"
          :min="1"
          :max="1"
          :step="stepCount"
          v-model="count"
        />
      </div>
      <div class="bid-splitline" />
      <div class="bid-rule2">
        <div class="bid-rule2-checkbox">
          <van-checkbox
            v-model="submitData.isCheckedAgreement"
            shape="square"
          />
        </div>
        <div class="bid-rule2-link">
          我已了解并同意
          <a
            target="_blank"
            href="https://wiki.qiandao.cn/docs/edgy_buyer_must_know"
          >
            《临界买家须知》
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="submit">
    <!--      @click="handleClickBtn"-->
    <div class="submit-btn">
      <van-button
        type="primary"
        size="large"
        full
        :disabled="disableSubmit"
        @click="handleSubmit"
      >
        确认购买
      </van-button>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  onMounted,
  computed,
  inject,
} from "vue";
import {
  Button as VanButton,
  Image as VanImage,
  Checkbox as VanCheckbox,
  showFailToast,
} from "vant";
import { storeToRefs } from "pinia";
import Stepper from "@/components/Stepper";
import { useUsersStore } from "@/store/user";
const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const spDetailData = inject("spDetailData");

const count = ref(1);
const initCount = ref(1);
const isFirstBid = ref(false);
const minPrice = ref(0);
const stepCount = ref(0);
const submitData = ref({
  isCheckedAgreement: false,
});
const tabs = ref([
  { name: "自动出价", type: "auto" },
  { name: "手动出价", type: "manual" },
]);

const props = defineProps({
  wasParticipate: {
    type: Boolean,
    required: true,
    default: false,
  },
  detailData: {
    type: Object,
    required: true,
    default: () => null,
  },
  auctionInfo: {
    type: Object,
    required: true,
    default: () => null,
  },
});

const emit = defineEmits(["onConfirm"]);

const disableSubmit = computed(() => {
  if (count.value < initCount.value) {
    return true;
  } else {
    return false;
  }
});

const handleSubmit = () => {
  if (!submitData.value.isCheckedAgreement) {
    return showFailToast("请确认已同意临界买家须知");
  } else {
    const tempInfo = JSON.parse(localStorage.getItem("userLocalInfo") || "{}");
    const currentUserInfo = tempInfo[userInfo.value.userId] || {};
    tempInfo[userInfo.value.userId] = {
      ...currentUserInfo,
      isAgreeLjMjxz: submitData.value.isCheckedAgreement,
    };
    localStorage.setItem("userLocalInfo", JSON.stringify(tempInfo));
  }
  emit("onConfirm");
};

onMounted(() => {
  userStore.handleLoginIfNot();
  const localInfo = JSON.parse(localStorage.getItem("userLocalInfo") || "{}");
  submitData.value.isCheckedAgreement =
    localInfo[userInfo.value.userId]?.isAgreeLjMjxz;
});
</script>

<style lang="scss">
.bid {
  padding: 88rpx 30rpx 0 30rpx;
  &-img {
    margin: 0 auto;
    width: 316rpx;
    height: 316rpx;
    filter: drop-shadow(0 8rpx 40rpx rgba(0, 0, 0, 0.06));
    border-radius: 24rpx;
    overflow: hidden;
    &-content {
      width: 100%;
      height: 100%;
    }
  }
  &-title {
    margin-top: 24rpx;
    font-weight: 900;
    font-size: 36rpx;
    line-height: 50rpx;
    color: #000000;
    text-align: center;
  }
  &-joined-tips {
    maring-top: 16rpx;
    font-weight: 400;
    font-size: 24rpx;
    line-height: 36rpx;
    color: #000000;
    text-align: center;
  }
  &-switch {
    margin: 8rpx auto 0 auto;
  }
  &-current {
    margin-top: 8rpx;
    text-align: center;
    &-tips {
      font-weight: 400;
      font-size: 24rpx;
      line-height: 36rpx;
      color: #000000;
      &-value {
        font-weight: 900;
      }
    }
  }
  &-stepper {
    margin-top: 20rpx;
  }
  &-rule {
    margin-top: 16rpx;
    font-weight: 400;
    font-size: 24rpx;
    line-height: 36rpx;
    color: #000000;
    text-align: center;
    a {
      color: var(--primary-purple);
      font-weight: bold;
      font-size: 24rpx;
      line-height: 36rpx;
      font-weight: 400;
    }
  }
  &-info {
    margin-top: 16rpx;
    text-align: center;
    &-prefix {
      font-weight: 400;
      font-size: 24rpx;
      line-height: 36rpx;
      color: rgba(0, 0, 0, 0.5);
    }
    &-value {
      margin-left: 8rpx;
      font-weight: 900;
      font-size: 24rpx;
      line-height: 36rpx;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  &-splitline {
    margin-top: 17rpx;
    width: 690rpx;
    //height: 2rpx;
    border-bottom: 2rpx solid rgba(0, 0, 0, 0.1);
  }
  &-rule2 {
    margin-top: 25rpx;
    display: flex;
    align-items: center;
    //justify-content: center;
    &-link {
      margin-left: 8rpx;
      font-weight: 400;
      font-size: 20rpx;
      color: rgba(0, 0, 0, 0.3);
      a {
        color: var(--primary-purple);
        font-weight: bold;
        font-size: 20rpx;
        font-weight: 400;
      }
    }
  }
}
.submit {
  margin: 16rpx auto 0 auto;
  //padding-bottom: 68rpx;
  width: 100%;
  height: calc(128rpx + 68rpx);
  background: #ffffff;
  overflow: hidden;
  &-btn {
    margin: 16rpx auto 0 auto;
    width: 690rpx;
  }
}
//.top-wrp {
//  background-color: #F8F8F8;
//}

//.van-stepper__input {
//  border: 2rpx solid red;
//}

:root:root {
  --van-button-border-width: 0;
  --van-popup-background: #f8f8f8;
}
</style>

<!--v-model	min max step-->
