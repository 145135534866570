<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="section" v-if="bidList.length">
    <div class="section-title">
      拍卖中
      <div class="section-title-subtitle">{{ bidCount }}次出价</div>
    </div>
    <div class="bwrp">
      <content-wrp>
        <div class="blist" v-for="(item, index) in bidList" :key="index">
          <div class="blist-item">
            <div class="blist-item-left">
              <img :src="item.avatar + '!mfit_w480_h480_jpg'" alt="" />
              <div class="bidinfo">
                <div
                  v-if="item.userId === userInfo.userId"
                  :class="`bidinfo-name bidinfo-name--me`"
                >
                  {{ item.userName }}{{ `(我-${item.bidUserPlate})` }}
                </div>
                <div v-else :class="`bidinfo-name`">
                  {{ item.userName }} {{ `(${item.bidUserPlate})` }}
                </div>
                <div class="bidinfo-time">
                  {{ item.timeString }}{{ item.isDelegate ? "(代理出价)" : "" }}
                </div>
              </div>
            </div>
            <div class="blist-item-right">
              <div class="blist-item-right-suffix" v-if="index === 0">
                最高出价
              </div>
              <div
                :class="`blist-item-right-price ${
                  index === 0 ? 'blist-item-right-price--highest' : ''
                }`"
              >
                ¥{{ item.bidPrice }}
              </div>
            </div>
          </div>
        </div>
      </content-wrp>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineExpose } from "vue";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import ContentWrp from "@/components/ContentWrp/index.vue";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import { useUsersStore } from "@/store/user";

const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const props = defineProps({
  collectionData: {
    type: Object,
    required: true,
  },
  auctionId: {
    type: String,
    required: true,
  },
  bidCount: {
    type: Number,
    required: true,
  },
});

const bidList = ref([]);
const isExpand = ref(false);

const handleExpand = () => {
  isExpand.value = true;
};

const initBidList = async () => {
  const res = await NftApi.getBidList({
    auctionId: props.auctionId,
    limit: 30,
    offset: 0,
  });

  if (res.code !== REQUEST_OK) return;

  const tempList = res.data || [];
  bidList.value = tempList.map((item) => {
    item.timeString = dayjs(+item.bidAt).format("MM-DD HH:mm:ss");
    return item;
  });
  console.log("res===!!", res.data);
};

initBidList();

defineExpose({
  initBidList,
});
</script>

<style lang="scss" scoped>
.bwrp {
  margin-top: 32rpx;
  .blist {
    &-item {
      margin-top: 24rpx;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        img {
          margin-right: 8rpx;
          width: 64rpx;
          height: 64rpx;
          border-radius: 100%;
          border: 2rpx solid rgba(0, 0, 0, 0.1);
        }
        .bidinfo {
          &-name {
            font-weight: 400;
            font-size: 20rpx;
            line-height: 22rpx;
            color: #000000;
          }
          &-name--me {
            font-weight: 900;
            color: var(--primary-purple);
          }
          &-time {
            margin-top: 8rpx;
            font-weight: 400;
            font-size: 20rpx;
            line-height: 22rpx;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;
        &-suffix {
          margin-right: 8rpx;
          font-weight: 400;
          font-size: 20rpx;
          line-height: 22rpx;
          color: #000000;
        }
        &-price {
          font-weight: 900;
          font-size: 20rpx;
          line-height: 22rpx;
          color: rgba(0, 0, 0, 0.5);
        }
        &-price--highest {
          color: var(--primary-purple);
        }
      }
    }
  }
  .blist:first-child {
    .blist-item {
      margin-top: 0;
    }
  }
}
</style>

<!--v-model	min max step-->
