<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="section">
    <div class="section-title">购前须知</div>
    <div :class="`tips ${noPadding ? 'tips--nopadding' : ''}`">
      <div v-if="currentStage">
        <div class="tips-title">{{ tipsInfo.title }}</div>
        <div class="tips-tutorial" @click="handleShowTutorial">
          教程：如何识别作品是可信的，防止受骗
          <ArrowRight :style="{ marginLeft: '0.5333333333333333rem' }" />
        </div>
        <div class="steps-wrp">
          <div
            :class="`tips-steps tips-steps--${index}`"
            v-for="(item, index) in tipsInfo.checkItems"
            :key="index"
            @click="handleClickStepItem(item)"
          >
            <div class="tips-steps-content">
              <span class="tips-steps-content-index">{{ index + 1 }}</span>
              <div class="tips-steps-content-title">
                {{ item.title }}
                <ArrowRight v-if="item.titleArrow" />
              </div>
              <div class="tips-steps-content-subtitle">{{ item.content }}</div>
            </div>
            <div class="tips-steps-scale" v-if="item.id === 'originImg'">
              <div class="magnifier" @click="handleShowCovers">
                <!--              TODO src-->
                <van-image
                  fit="cover"
                  class="magnifier-img"
                  :src="watermaskImage + '!mfit_w750_h750_jpg'"
                  alt=""
                />
                <img
                  class="magnifier-icon"
                  src="https://cdn.qiandaoapp.com/ued/9c0dc70eaaccb3ee9c9a940bc5184436.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          :class="`tips-process-title ${
            !currentStage ? 'tips-process-title--nomargin' : ''
          }`"
        >
          出现纠纷可联系平台客服
        </div>
        <img
          class="tips-process-img"
          src="https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg"
          alt=""
        />
      </div>
    </div>

    <van-image-preview
      v-model:show="isShowPreview"
      :images="[watermaskImage]"
      className="BACKGROUND_LINEAR_PURPLE"
      :maxZoom="1"
      :minZoom="1"
    >
      <template v-slot:cover>
        <div class="preview-btn" @click="handleClickDetail">
          去app查看更多细节
        </div>
      </template>
    </van-image-preview>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, inject } from "vue";
import axios from "axios";
import {
  Image as VanImage,
  showImagePreview,
  ImagePreview as VanImagePreview,
} from "vant";
import ArrowRight from "@/components/ArrowRight/index.vue";
import { isProd } from "@/utils/env-related";
import { invokeAppByYYB } from "@/utils/mob";

const tokenId = inject("tokenId");

const props = defineProps({
  noPadding: {
    type: Boolean,
    required: false,
  },
  currentStage: {
    type: Number,
    required: true,
  },
  creatorId: {
    type: String,
    required: true,
  },
  watermaskImage: {
    type: String,
    required: true,
  },
});

const tipsInfo = ref({});
const isShowPreview = ref(false);

const handleShowTutorial = () => {
  window.open(`https://wiki.qiandao.cn/docs/edgy_buyer_identify_instruction`);
};

const handleShowCovers = () => {
  // const tempImgs = [props.watermaskImage]; // TODO use realImg
  isShowPreview.value = true;
};

const handleClickDetail = () => {
  invokeAppByYYB({
    targetPath: "token-detail",
    params: {
      tokenId: tokenId.value,
    },
  });
};

const handleClickStepItem = (item) => {
  console.log(props.creatorId);
  if (item.id === "tokenCertificate") {
    invokeAppByYYB({
      targetPath: `token/proof`,
      params: {
        tokenId: tokenId.value,
      },
    });
  } else if (item.id === "creatorCertification") {
    invokeAppByYYB({
      targetPath: `creator/apply/detail`,
      params: {
        userId: props.creatorId, // creatorId
      },
    });
  }
};

const initStepsConfig = async () => {
  let res = "";
  if (isProd) {
    res = await axios.get(
      "https://config-cdn.qiandaoapp.com/congress/common-config.json"
    );
  } else {
    res = await axios.get(
      "https://dev-config-cdn.qiandaoapp.com/congress/common-config.json"
    );
  }
  const stepsConfig = res?.data?.tradeNotice?.identifySteps || [];
  const { currentStage } = props;
  const tempConfigInfo = stepsConfig.find(
    (item) => item.purchaseType == currentStage
  );
  const tempStepsList = tempConfigInfo?.steps;
  tipsInfo.value = {
    title: tempConfigInfo?.title,
    checkItems: tempStepsList,
  };
  console.log("stepsConfig", stepsConfig);
};

onMounted(() => {
  initStepsConfig();
});
</script>

<style lang="scss" scoped>
.tips {
  margin-top: 32rpx;
  width: 690rpx;
  padding: 32rpx;
  /* height: 208rpx; */
  background: #ffffff;
  box-shadow: 0px 8rpx 40rpx rgba(0, 0, 0, 0.06);
  border-radius: 32rpx;
  &-title {
    color: #000;
    /* Heavy/H5 */
    font-size: 32rpx;
    font-weight: 500;
    line-height: 48rpx; /* 150% */
  }
  &-tutorial {
    display: inline-block;
    margin-top: 24rpx;
    //display: flex;
    padding: 16rpx;
    align-items: center;
    border-radius: 16rpx;
    background: #f8f8f8;
    color: rgba(0, 0, 0, 0.7);
    text-align: right;
    font-size: 24rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 36rpx; /* 150% */
  }
  .steps-wrp {
    margin-top: 32rpx;
  }
  &-steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 28rpx;
    //background-color: skyblue;
    &-content {
      position: relative;
      display: flex;
      //height: 44px;
      flex: 1;
      padding: 16rpx 0 16rpx 48rpx;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1 0 0;
      border-radius: 16rpx;
      background: linear-gradient(
        307deg,
        rgba(0, 0, 0, 0.03) 53.39%,
        rgba(0, 0, 0, 0) 89.71%
      );
      &-index {
        position: absolute;
        left: 0;
        top: -32rpx;
        color: #f2f2f2;
        font-family: "Roboto";
        font-size: 80rpx;
        font-style: italic;
        font-weight: 700;
        line-height: 80rpx; /* 100% */
      }
      &-title {
        color: rgba(0, 0, 0, 0.7);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
      }
      &-subtitle {
        margin-top: 4rpx;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 22rpx; /* 110% */
        text-transform: capitalize;
      }
    }
    &-scale {
      width: 88rpx;
      margin-left: 16rpx;
      .magnifier {
        position: relative;
        margin-right: 16rpx;
        width: 88rpx;
        height: 88rpx;
        box-shadow: 8rpx 8rpx 16rpx rgba(0, 0, 0, 0.06);
        border-radius: 24rpx;
        overflow: hidden;
        //background-color: red;
        &-img {
          width: 88rpx;
          height: 88rpx;
        }
        &-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 40rpx;
          height: 40rpx;
        }
      }
    }
  }
  //&-steps--0 {
  //  padding-top: 0;
  //}
  &-process-title {
    margin-top: 32rpx;
    font-weight: 400;
    font-size: 24rpx;
    line-height: 36rpx;
    color: rgba(0, 0, 0, 0.5);
  }
  &-process-title--nomargin {
    margin-top: unset;
  }
  &-process-img {
    margin-top: 16rpx;
    width: 100%;
  }
}
.tips--nopadding {
  padding: unset;
  box-shadow: unset;
  .tips-tutorial {
    padding: unset;
  }
}

.preview-btn {
  position: fixed;
  bottom: 200rpx;
  left: 50%;
  transform: translateX(-50%);
  padding: 24rpx 80rpx;
  width: 516rpx;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 16rpx;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8rpx);
  color: #fff;
  font-size: 32rpx;
  font-style: normal;
  font-weight: 500;
  line-height: 48rpx; /* 150% */
  letter-spacing: 0.32rpx;
}
</style>

<!--v-model	min max step-->
