<!--
   Created by Terence on 2024/2/21 - 17:00
-->
<!--eslint-disable-->
<template>
  <div class="rechargedl">
    <van-dialog v-model:show="modelValue" @close="handleClose" title="" confirmButtonColor="rgba(0, 0, 0, 0.50)" confirmButtonText="关闭">
      <div class="rechargedl-content">
<!--        <p><strong>保证金比例1:20</strong> ，即充值100元保证金，能获得出价额度2000元</p>-->
<!--        <p>保证金支付进入<strong>「保证金账户」，出价领先或中拍拍品后将会冻结保证金，</strong>当出价被超越或成功支付拍品订单即解冻，解冻后可提现</p>-->
<!--        <p>成交后若 (超过保留价) 买家跑单，将扣除买家保证金</p>-->
<!--        <p>参与竞拍的新用户可享受「累计5元保证金额度的先参拍后缴纳」权益</p>-->
<!--        <div v-html="'https://config-cdn.qiandaoapp.com/edgy/edgy_auction_deposit_rules.html'" />-->
        <div v-html="htmlContent" class="htmlContent"></div>
        <div class="rechargedl-btn" @click="toMyDepositAccountPage">
          查看我的保证金账户
          <ArrowRight :style="{ marginLeft: '0.26666666666666666rem' }" type="primary" />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  defineProps,
  defineEmits,
  onMounted,
  inject,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import { Dialog as VanDialog } from "vant";
import ArrowRight from "@/components/ArrowRight/index.vue";

const router = useRouter();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "onConfirmed"]);

const htmlContent = ref("");

const handleClose = () => {
  emit("update:modelValue", false);
};

const toMyDepositAccountPage = () => {
  router.push(`/wallet`);
};

const handleRechargeRules = () => {
  // TODO 接入rules弹窗
};

function getHtmlContent() {
  const htmlFileUrl =
    "https://config-cdn.qiandaoapp.com/edgy/edgy_auction_deposit_rules.html";

  fetch(htmlFileUrl)
    .then((response) => response.text())
    .then((res) => {
      htmlContent.value = res;
    })
    .catch((error) => {
      console.error("Error fetching HTML: ", error);
    });
}

onMounted(() => {
  getHtmlContent();
  //
});
</script>

<style lang="scss">
.rechargedl {
  .htmlContent {
    color: #000;
    font-size: 36rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 50rpx;
  }
  &-content {
    //padding: 80rpx 48rpx 0 48rpx;
    padding: 80rpx 48rpx 0 48rpx;
    color: #000;
    font-size: 36rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 50rpx;
    p {
      position: relative;
    }
    p::before {
      content: "·";
      position: absolute;
      left: -40rpx;
      font-size: 105rpx;
    }
  }
  &-btn {
    text-align: center;
    margin: 16rpx auto 24rpx auto;
    padding: 16rpx 0;
    color: #7247dc;
    font-size: 32rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 48rpx; /* 150% */
  }
}
</style>
