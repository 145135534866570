<template>
  <div class="tswitch">
    <div
      class="tswitch-item"
      v-for="(item, index) in tabs"
      :key="index"
      @click="handleClickItem(item, index)"
    >
      <div class="tswitch-item-activeblock" v-if="index === 0" />
      <span
        :class="`tswitch-item-name ${
          currentIndex === index && 'tswitch-item-name--active'
        }`"
      >
        {{ item.name }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted } from "vue";

// const tabs = ref(
//   [
//     {name: '自动出价', key: 'auto'},
//     {name: '手动出价', key: 'manual'},
//   ]
// )

const currentIndex = ref(0);
const inputValue = ref(0);
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
    default: () => [],
  },
});
const emit = defineEmits(["update:modelValue", "onClickItem"]);

watch(inputValue, (value) => {
  // emit("update:modelValue", value);
});

const handleClickItem = (item, index) => {
  currentIndex.value = index;
  const itemWidth =
    document.getElementsByClassName("tswitch-item")[0].clientWidth - 2;
  // console.log(index);
  // document.getElementsByClassName('tswitch-item')[0].children[0].style.transform = `translateY(-50%) translateX(${itemWidth * index}px)`
  document.getElementsByClassName(
    "tswitch-item"
  )[0].children[0].style.transform = `translate3d(${
    itemWidth * index
  }px, -50%, 0)`;
  emit("onClickItem", item);
};

onMounted(() => {
  // inputValue.value = props.modelValue;
});
</script>

<style lang="scss">
:root {
  //--lj-stepper-input-width: 200rpx;
  //--lj-stepper-input-height: 68rpx;
  --lj-tswitch-width: 610rpx;
  --lj-tswitch-height: 72rpx;
  --lj-tswitch-indent-gap: 2rpx;
}

.tswitch {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--lj-tswitch-width);
  height: var(--lj-tswitch-height);
  border-radius: 10rpx;
  border: 2rpx solid rgba(0, 0, 0, 0.08);
  &-item {
    position: relative;
    flex: 1;
    text-align: center;
    line-height: var(--lj-tswitch-height);
    //background: skyblue;
    &-activeblock {
      visibility: hidden;
    }
    &-name {
      font-weight: 800;
      font-size: 32rpx;
      line-height: 48rpx;
      color: rgba(0, 0, 0, 0.4);
    }
    &-name--active {
      color: #ffffff;
    }
  }
  &-item:nth-child(1) {
    //background-color: red;
    transition: all 0.2s;
    .tswitch-item-activeblock {
      transition: all 0.2s;
      visibility: visible;
      position: absolute;
      z-index: -1;
      //width: calc(100% - 6rpx);
      width: calc(100% - var(--lj-tswitch-indent-gap));
      //z-index: -1;
      //left: calc(50% + var(--lj-tswitch-indent-gap) / 2);
      top: 50%;
      left: var(--lj-tswitch-indent-gap);
      transform: translate3d(0, -50%, 0);
      height: calc(var(--lj-tswitch-height) - var(--lj-tswitch-indent-gap));
      background: var(--primary-linear-gradient);
      border-radius: 8rpx;
    }
  }
}
</style>
