<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="tips">
    <div class="tips-left">
      <img
        class="tips-left-icon"
        src="https://cdn.qiandaoapp.com/interior/images/a5c11636d66d536675bd5de4c601bea0.png!mfit_w480_h480_png"
        alt=""
      />
      <div class="tips-left-content">
        <div class="tips-left-content-title">出价成功！</div>
      </div>
    </div>
    <div class="tips-right">
      <DuButton type="recommend" size="small" @click="handleToWalletPage"
        >立即补缴保证金</DuButton
      >
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { DuButton } from "@frontend/dumpling";
import MainButton from "@/components/MainButton";
import NftApi from "@/apis";
import { useUsersStore } from "@/store/user";
import { useRouter } from "vue-router";

const userStore = useUsersStore();
const router = useRouter();

const { userInfo, getIsLogin } = storeToRefs(userStore);

const props = defineProps({
  detailData: {
    type: Object,
    required: true,
  },
});

const handleToWalletPage = (e) => {
  router.push(`/wallet`);
};

// onMounted(() => {});
</script>

<style lang="scss" scoped>
.tips {
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -98%);
  display: flex;
  padding: 16rpx 24rpx;
  justify-content: center;
  align-items: center;
  border-radius: 8rpx;
  background: #fff;
  box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
  &-left {
    flex: 1;
    display: flex;
    &-icon {
      width: 64rpx;
      height: 80rpx;
    }
    &-content {
      display: flex;
      align-items: center;
      margin-left: 16rpx;
      &-title {
        color: #000;
        font-size: 36rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 40rpx; /* 138.889% */
      }
      &-desc {
        margin-top: 4rpx;
        color: rgba(0, 0, 0, 0.4);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
      }
    }
  }
}
</style>

<!--v-model	min max step-->
