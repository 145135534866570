<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div
    v-if="suspectedCount"
    :class="`suspect ${isDanger ? 'suspect--danger' : ''}`"
    @click="handleToSuspect"
  >
    <span class="suspect-text">{{ suspectedCount }}位用户对该作品提出疑议</span>
    <img
      class="arrow"
      v-if="isDanger"
      src="https://cdn.qiandaoapp.com/interior/images/536157afb1c1324aa6c3f70702724522.png"
      alt=""
    />
    <img
      class="arrow"
      v-else
      src="https://cdn.qiandaoapp.com/interior/images/fd0a96ed5b3c8bd9177917a47ff6a913.png"
      alt=""
    />
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getQuery, transUrlRelatedType } from "@/utils/url";
import NftApi from "@/apis";

const router = useRouter();

const props = defineProps({
  tradeType: {
    type: String,
    default: "",
  },
});

const suspectedCount = ref(0);

const isDanger = computed(() => {
  return suspectedCount.value >= 3;
});

const handleToSuspect = () => {
  const { tradeId } = getQuery();
  router.push(
    `/suspect-detail?relatedId=${tradeId}&relatedType=${props.tradeType}`
  );
};

const initSuspectInfo = async () => {
  const { tradeId } = getQuery();
  const { code, data } = await NftApi.getSuspectListByTopic({
    topicId: tradeId,
    topicType: transUrlRelatedType(props.tradeType),
    filterSuspectId: [],
    limit: 1000,
    offset: 0,
  });
  suspectedCount.value = +data.total;
};

onMounted(() => {
  initSuspectInfo();
});
</script>

<style lang="scss" scoped>
.suspect {
  position: sticky;
  top: 0;
  margin: 0 auto;
  display: flex;
  width: 690rpx;
  padding: 20rpx 30rpx;
  justify-content: center;
  align-items: center;
  border-radius: 16rpx;
  background: #ffebe9;
  &-text {
    color: #d94a4e;
    font-size: 24rpx;
    font-weight: 500;
    line-height: 36rpx; /* 150% */
  }
  .arrow {
    margin-left: 4rpx;
    width: 16rpx;
    height: 16rpx;
  }
}
.suspect--danger {
  background-color: #d94a4e;
  .suspect-text {
    color: #ffebe9;
  }
}
</style>

<!--v-model	min max step-->
