<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<!--eslint-disable-->
<template>
  <div class="welfare-popup">
    <van-popup
      v-model:show="modelValue"
      closeable
      position="center"
      lock-scroll
      close-icon="https://cdn.qiandaoapp.com/ued/d80d10918056cda4940aa8ccd6b01784.png"
      @close="handleClose"
      round
    >
      <div class="content">
        <div class="content-title">符合以下条件获得购买资格</div>
        <div class="content-qualifications">
          <div
            v-for="(item, index) in actionConfig.uiConfig?.buyQualifications"
            :key="index"
          >
            <div
              :class="`role-item ${
                !item.isSatisfied ? 'role-item--inactive' : ''
              }`"
            >
              <img
                class="role-item-icon"
                v-if="item.isSatisfied"
                src="https://cdn.qiandaoapp.com/interior/images/f4a3e378d4510cadab1c5b2e767e6689.png"
                alt=""
              />
              <img
                class="role-item-icon"
                v-else
                src="https://cdn.qiandaoapp.com/interior/images/c6bb70cdfe389f071364bff80b954e67.png"
                alt=""
              />
              <div>{{ item.description }}</div>
            </div>
          </div>
        </div>
        <div class="content-subtitle">购前须知</div>
        <div class="content-desc">
          <div>
            <span class="numindex">1.&nbsp;</span>
            请确保约稿角色已创建档案，为防止机器代抢和黄牛转卖，成功抢到稿位的用户会被即时记录角色档案信息
          </div>
          <div>
            <span class="numindex">2.&nbsp;</span>(快照)，画师只接受快照内的角色约稿。
            本期所有福利商品，每人限购1个。
          </div>
          <div>
            <span class="numindex">3.&nbsp;</span>购买条件数据更新可能有5分钟延迟，请尽早确认自己符合购买条件。
          </div>
        </div>
      </div>
      <div class="btn-wrp">
        <van-button type="primary" size="large" @click="handleSubmit">
          知道啦
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, inject } from "vue";
import { Popup as VanPopup, Button as VanButton } from "vant";
import PrePurchaseTips from "@/components/PrePurchaseTips";

const props = defineProps({
  actionConfig: {
    type: Object,
    default: () => ({}),
  },
  isSatisfyBuyQualification: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "onConfirmed"]);

const prePurchaseConfig = inject("prePurchaseConfig");

const handleClose = () => {
  emit("update:modelValue", false);
};

const handleSubmit = () => {
  emit("onConfirmed");
};

onMounted(() => {
  //
});
</script>

<style lang="scss">
:root:root {
  .welfare-popup {
    --van-popup-background: white;
  }
}
.welfare-popup {
  .content {
    padding: 60rpx 30rpx;
    &-title {
      color: #000;
      font-size: 40rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 52rpx; /* 130% */
    }
    .role-item {
      display: flex;
      align-items: center;
      margin-bottom: 16rpx;
      padding: 16rpx;
      color: #7247dc;
      font-size: 28rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 44rpx; /* 157.143% */
      border-radius: 64rpx;
      background: linear-gradient(
        151deg,
        rgba(43, 26, 192, 0.05) 0%,
        rgba(114, 71, 220, 0.05) 100%
      );
      &-icon {
        display: block;
        width: 36rpx;
        margin-right: 12rpx;
      }
    }
    .role-item--inactive {
      background: #f8f8f8;
      color: rgba(0, 0, 0, 0.5);
    }
    &-subtitle {
      margin-top: 32rpx;
      color: #000;
      font-size: 28rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 44rpx; /* 157.143% */
    }
    &-desc {
      display: flex;
      flex-direction: column;
      margin-top: 16rpx;
      color: rgba(0, 0, 0, 0.5);
      font-size: 28rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 44rpx; /* 157.143% */
      & > div {
        display: flex;
        .numindex {
          display: block;
        }
      }
    }
  }
  .btn-wrp {
    padding: 16rpx 30rpx;
    margin-bottom: 60rpx;
  }
}
</style>
