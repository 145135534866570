/**
 * Created by Terence on 2023/5/30 - 11:28
 * Description :
 */
import { ref } from "vue";
import {
  AUCTION_BTN_STAGE,
  AUCTION_STAGE,
  QIANDAO_AUCTION_STAGE,
  RESERVERPRICE_STAGE,
} from "@/constant/auction";
import { REQUEST_OK, PRODUCT_ERROR_TYPE } from "@/constant/network";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";
import {
  showConfirmDialog,
  showLoadingToast,
  showSuccessToast,
  closeToast,
  showFailToast,
} from "vant";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { emitter } from "@/utils/emitter.js";
import NftApi from "@/apis";
import BidApi from "@/apis/bid";
import { PRE_PAY_POPUP_TYPE } from "@/constant/payment";
import { ART_TYPE, TRADE_STATUS } from "@/constant/contract";
import { formatTimestamp, getCountdownTime } from "@/utils/time";
import dayjs from "dayjs";
import ProductApi from "@/apis/product";

export interface IBtnInfo {
  [key: string]: any;
}

export interface IBuyoutInfo {
  product: any;
  otherDetail: any;
}

export interface IAuctionInfo {
  [key: string]: any;
}

export interface IActionConfig {
  isShowActionBar: boolean;
  // iAmMaxBidUser: boolean;
  // isSeller: boolean;
  isC2CBuyer: boolean;
  confirmOrderId: string;
  // tradeInfo: {
  //   tradeOrderId: string;
  // };
  uiConfig: {
    [key: string]: any;
    btnInfo: IBtnInfo;
    price: number;
    buyoutInfo: IBuyoutInfo;
  };
}
const handleEmitToRefreshToken = () => {
  emitter.emit("INIT_TOKEN_DETAIL");
};

const calcIncrementsAmount = (range: any[], price: number) => {
  for (const item of range) {
    // console.log('item===!', item);
    if (item.min <= price && (price < item.max || item.max === -1)) {
      return item.step;
    }
  }
};

export function useTokenFactory() {
  // const isShowPrePurchasePopup = ref(false);
  const test111 = ref<any>(null);
  const isBuyoutType = ref(true);
  const tradeType = ref("buyout");
  const originTradeType = ref("");
  const localTokenId = ref("");

  const generateUiConfig = ({
    isBuyoutType,
    confirmData,
    auctionInfo,
    buyoutInfo,
    // tradeInfo,
    isSeller,
    isLeader,
  }: // isShowWelfareTips,
  // isSatisfyBuyQualification,
  // buyQualifications,
  any) => {
    const tempConfig = {
      waitConfirmInAppText: "",
      topTipsText: "",
      currentPrice: auctionInfo?.currentPrice, // 拍卖当前价
      // price: buyoutInfo?.product?.price, // 一口价 价格
      // stock: buyoutInfo?.product?.stock, // 一口价 库存
      endTime: "",
      // isShowWelfareTips,
      // isSatisfyBuyQualification,
      // buyQualifications,
      showSubscribe: false,
      isSubscribe: auctionInfo?.isFollow,
      endSummary: "", // 订单结束后
      btnInfo: {
        disabled: false,
        disabledType: "default",
        text: "出价",
        extStyle: "",
        extClass: "",
        inactivated: false,
        stage: AUCTION_BTN_STAGE.ON_BIDDING,
      },
    };

    console.log("isBuyoutType===", isBuyoutType);
    console.log("auctionInfo===", auctionInfo);

    // 一口价&批发
    if (isBuyoutType.value) {
      const { startTime, stock, status } = buyoutInfo?.product || {};
      tempConfig.btnInfo.text = "购买";
      tempConfig.btnInfo.stage = AUCTION_BTN_STAGE.ON_PAY;

      if (+new Date() < +startTime) {
        tempConfig.btnInfo.text = "未开售";
        tempConfig.btnInfo.stage = AUCTION_BTN_STAGE.NO_QUALIFICATIONS;
        tempConfig.btnInfo.disabled = true;
        tempConfig.btnInfo.disabledType = "temp";
        // tempConfig.btnInfo.extClass = "hugewidth";
        // tempConfig.btnInfo.inactivated = true;
      }
      // 已售空
      if (!stock) {
        tempConfig.btnInfo.disabled = true;
      }
      // 已下架
      if (status === "OFF_SALE") {
        tempConfig.btnInfo.disabled = true;
      }
      // 已售出
      if (status === "CLOSED") {
        tempConfig.btnInfo.disabled = true;
      }

      // if (isShowWelfareTips) {
      //   tempConfig.btnInfo.text = "请去App购买";
      //   tempConfig.btnInfo.stage = AUCTION_BTN_STAGE.NO_QUALIFICATIONS;
      //   tempConfig.btnInfo.extClass = "hugewidth";
      //   tempConfig.btnInfo.inactivated = true;
      // }
    } else {
      // 拍卖

      // auctionInfo.statusEnum = QIANDAO_AUCTION_STAGE.AuctionStatus_WaitSellerConfirm; // TODO remove

      auctionInfo.isFutureSelling = auctionInfo.startTime > +new Date();
      auctionInfo.startTimeString = formatTimestamp(+auctionInfo?.startTime);
      auctionInfo.finishTimeString = formatTimestamp(+auctionInfo?.finishTime);
      auctionInfo.confirmTimeString = formatTimestamp(
        +auctionInfo?.sellerConfirmDeadLine
      );

      // h5 上面拍卖就先 不 支持支付了
      // if (
      //   isLeader &&
      //   auctionInfo.statusEnum === QIANDAO_AUCTION_STAGE.AuctionStatus_Close &&
      //   !auctionInfo.isPerformance
      // ) {
      //
      //   // tempConfig.btnInfo.text = "支付";
      //   // tempConfig.btnInfo.stage = AUCTION_BTN_STAGE.ON_PAY;
      // }

      // 目前表示为prepare
      if (
        auctionInfo.statusEnum ===
          QIANDAO_AUCTION_STAGE.AuctionStatus_Bidding &&
        auctionInfo.isFutureSelling
      ) {
        tempConfig.btnInfo.text = "";
        tempConfig.showSubscribe = true;
      } else if (
        auctionInfo.statusEnum === QIANDAO_AUCTION_STAGE.AuctionStatus_Bidding
      ) {
        tempConfig.btnInfo.text = "出价";
        tempConfig.showSubscribe = true;
      } else if (
        auctionInfo.statusEnum === QIANDAO_AUCTION_STAGE.AuctionStatus_Flowed // 流拍 后端任务 执行完成
      ) {
        tempConfig.btnInfo.text = "";
        tempConfig.endSummary = "流拍";
      } else if (
        auctionInfo.statusEnum === QIANDAO_AUCTION_STAGE.AuctionStatus_Close // 成交
      ) {
        tempConfig.btnInfo.text = "";
        tempConfig.endSummary = `¥${auctionInfo?.currentPrice} 成交`;
      } else if (
        auctionInfo.statusEnum ===
        QIANDAO_AUCTION_STAGE.AuctionStatus_WaitSellerConfirm // 待卖家确认
      ) {
        tempConfig.btnInfo.text = "已截拍待卖家确认";
        tempConfig.btnInfo.disabled = true;
        tempConfig.btnInfo.disabledType = "temp";
        tempConfig.btnInfo.stage = AUCTION_BTN_STAGE.WAIT_FOR_SELLER_CONFIRM;
        tempConfig.btnInfo.extClass = "hugewidth";
      }
    }

    if (+confirmData.total) {
      tempConfig.waitConfirmInAppText = `有${confirmData.total}个未完成的订单，请前往App操作`;
    }

    // 有保留价
    if (auctionInfo?.reversePrice) {
      if (+auctionInfo.currentPrice >= auctionInfo?.reversePrice) {
        tempConfig.topTipsText = "已超过保留价";
        // tempConfig.btnInfo.text = ""; // 截拍后 超过保留价不显示btn?!
      } else {
        tempConfig.topTipsText = "未超过保留价，截拍后卖家可能选择不出售作品";
      }
    }

    return tempConfig;
  };

  const generateActionConfig = async ({
    // isBuyoutType,
    iAmMaxBidUser,
    loaded,
    detailData,
    auctionInfo,
    userInfo,
    tradeInfoData,
    getIsLogin,
  }: any) => {
    const { tradeId } = getQuery();

    const { data: commonConfig } = await NftApi.getCommonConfig();

    const basicProductInfoRes: any = await NftApi.getTypeOfTrade({ tradeId });

    if (basicProductInfoRes?.data?.tradeType === "AUCTION") {
      isBuyoutType.value = false;
      tradeType.value = "auction";
    } else if (basicProductInfoRes?.data?.tradeType === "TOKEN") {
      tradeType.value = "token";
    } else if (basicProductInfoRes?.data?.tradeType === "C2C_PRODUCT") {
      tradeType.value = "buyout";
    }
    originTradeType.value = basicProductInfoRes?.data?.tradeType;

    const tempProductType = basicProductInfoRes?.data?.productType;
    const isCommissionProduct =
      basicProductInfoRes?.data?.productType === "COMMISSION";

    console.log("isCommissionProduct===", isCommissionProduct);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let tempBuyoutDetailData: IBuyoutInfo = {};
    let tempAuctionDetailData: any = {};
    let tempMyBidInfo: any = {};
    let resErrorType = "";

    if (isBuyoutType.value) {
      const buyoutRes: any = await ProductApi.getBuyoutProductDetail({
        id: tradeId,
      });

      console.log("buyoutRes.code===", buyoutRes.code);

      if (buyoutRes.code != REQUEST_OK) {
        resErrorType = buyoutRes.code;
      }

      tempBuyoutDetailData = buyoutRes.data || {};

      localTokenId.value = buyoutRes?.data?.product?.tokenId;
    } else {
      const auctionRes: any = await ProductApi.getAuctionProductDetail({
        id: tradeId,
      });

      if (auctionRes.code != REQUEST_OK) {
        resErrorType = auctionRes.code;
      }
      localTokenId.value = auctionRes?.data?.tokenId;

      tempAuctionDetailData = auctionRes.data || {};
      tempAuctionDetailData.startPrice = tempAuctionDetailData.startingPrice;
      tempAuctionDetailData.bidCount = tempAuctionDetailData.bidderCount;
      tempAuctionDetailData.auctionId = tempAuctionDetailData.id;
      tempAuctionDetailData.incrementsAmount = calcIncrementsAmount(
        commonConfig?.tradeNotice?.auctionBidSteps || [],
        tempAuctionDetailData.currentPrice ||
          tempAuctionDetailData?.startingPrice
      );

      const { data: bidInfo }: any = await BidApi.getMyBidInfo({
        auctionId: tradeId,
      });
      tempMyBidInfo = bidInfo;
    }

    let tempTokenData = null;
    let confirmData: any = {};

    if (!isCommissionProduct) {
      const { data }: any = await NftApi.getTokenDetail({
        tokenId: localTokenId.value,
      });
      tempTokenData = data;
      if (getIsLogin.value) {
        const { data } = await NftApi.getTokenConfirmStatus({
          tradeOrderId: tradeId,
          type: isBuyoutType.value ? "C2C" : "AUCTION",
        });
        confirmData = data;
      }
    }

    console.log("confirmData===", confirmData);
    loaded.value = true;
    detailData.value = tempTokenData?.token || {};

    const isC2CBuyer =
      detailData.value.tradeInfo?.type === "SALE" &&
      (detailData.value.tradeInfo?.status === "SELLING" ||
        detailData.value.tradeInfo?.status === "FUTURE_SELLING") &&
      !detailData.value.isOwner;

    // let isShowWelfareTips = false;
    // let isSatisfyBuyQualification = true;
    // let buyQualifications = [];

    // const { data: tempTradeInfoData }: any = await NftApi.getTokenTradeInfo({
    //   tokenId,
    // });
    // isShowWelfareTips = tempTradeInfoData?.saleOrder?.isBuyLimit; // 作品是否 福利作品
    // isSatisfyBuyQualification =
    //   tempTradeInfoData?.saleOrder?.isSatisfyBuyQualification; // 买家是活跃买家
    // buyQualifications = tempTradeInfoData?.saleOrder?.buyQualifications;

    // console.log('tempTradeInfoData===', tempTradeInfoData);

    // tradeInfoData.value = tempTradeInfoData;

    console.log("tradeInfoData.valuetradeInfoData.value", tradeInfoData.value);
    const tempActionConfig: IActionConfig = {
      isShowActionBar: false,
      // iAmMaxBidUser, // 当前登录人是否是中拍者
      // isSeller, // 是否是卖家
      isC2CBuyer,
      // tradeInfo: data?.token?.tradeInfo, // 废弃 老的token上的交易信息
      confirmOrderId: confirmData.orderId,
      uiConfig: generateUiConfig({
        isBuyoutType,
        confirmData,
        // isSeller,
        isLeader: tempMyBidInfo.isLeader,
        // isShowWelfareTips,
        // isSatisfyBuyQualification,
        // buyQualifications,
        auctionInfo: tempAuctionDetailData,
        buyoutInfo: tempBuyoutDetailData,
      }) as any,
    };

    // 履约信息
    const tempContractPerformingConfig = {
      isProgressivePayment: false, // 分阶段付款
    };
    const { artType } = detailData.value;
    // 稿位类型 是 分阶段履约 (不直接跳转支付结果页)
    if (artType === ART_TYPE.SPACE_FOR_ARTWORK) {
      tempContractPerformingConfig.isProgressivePayment = true;
    }

    return {
      tempProductType,
      tempTokenId: localTokenId.value,
      tempIsBuyoutType: isBuyoutType.value,
      tempTradeType: tradeType.value,
      tempOriginTradeType: originTradeType.value,
      tempResErrorType: resErrorType,
      tempDetailData: detailData,
      // tempTradeInfo: tempTradeInfoData,
      tempActionConfig,
      tempContractPerformingConfig,
      tempBuyoutDetailData,
      tempAuctionDetailData,
      tempMyBidInfo,
    };
  };

  return {
    generateActionConfig,
  };
}
