<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <!--  <div :class="['page', actionConfig.isShowActionBar && 'page&#45;&#45;withauction']">-->
  <div :class="['page', 'page--withauction']">
    <van-sticky>
      <van-nav-bar left-arrow title="商品详情" @click-left="onClickLeft" />
    </van-sticky>
    <!--    <browser-cover />-->
    <download-banner @onInvokeApp="onInvokeApp" />
    <suspect-notice-sp
      :tradeType="tradeType"
      v-if="productType === 'COMMISSION'"
    />
    <suspect-notice v-else :suspectedCount="+detailData.suspectedCount" />

    <!--    <HocBtn :text="1112" color="primary" disabled disabled-type="temp" />-->
    <div v-if="!resErrorType && loaded" class="token-content">
      <template v-if="productType === 'TOKEN'">
        <div class="binfo-wrp">
          <basic-info @onInvokeApp="onInvokeApp" />
        </div>
        <div class="pinfo-wrp">
          <product-info />
        </div>
      </template>

      <template v-if="productType === 'COMMISSION'">
        <div class="binfo-wrp">
          <basic-info-sp @onInvokeApp="onInvokeApp" />
        </div>
        <div class="pinfo-wrp">
          <product-info-sp />
        </div>
      </template>

      <div ref="bigTokenNoRef" />

      <!--      <div class="head">-->

      <!--        &lt;!&ndash;      TODO 超过4行隐藏  https://blog.csdn.net/Dilomen/article/details/102756534 &ndash;&gt;-->
      <!--        <div class="content" v-if="detailData.description">-->
      <!--          &lt;!&ndash;      <div class="section-title">作品描述</div>&ndash;&gt;-->
      <!--          <div class="content-value">{{ detailData.description }}</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <AuctionField
        v-if="auctionDetailData?.field"
        :field="auctionDetailData.field"
      />
      <!--  拍卖 出价表  -->
      <bid-list
        ref="bidList"
        v-if="isAuctionBidding"
        :bidCount="auctionDetailData.currentBidsCount"
        :auctionId="auctionDetailData.id"
      />

      <!--  持有者  -->
      <author-info v-if="productType === 'TOKEN'" :detailData="detailData" />

      <!--  当前登录人是持有者  -->
      <owner-actions
        v-if="detailData.isOwner && detailData.artType !== 'SPACE_FOR_ARTWORK'"
        :originImages="detailData.originImages"
      />

      <!--  购前须知  -->
      <pre-purchase-tips
        v-if="detailData.tradeInfo?.status === 'SELLING'"
        :currentStage="detailData?.purchaseInstructionType"
        :creatorId="detailData?.publisher?.userId"
        :watermaskImage="detailData?.watermaskImages?.[0]?.url"
      />

      <!--  流程提示  -->
      <!--    <process-tips :detailData="detailData" />-->

      <!--  评论列表  -->
      <div v-if="tokenId">
        <comment-list
          :productType="productType"
          :originTradeType="originTradeType"
        />
      </div>
      <!--  action btn  -->
      <!--    <div class="auction" v-if="detailData.tradeInfo?.type === 'AUCTION'">-->
      <!--  拍卖:出价&支付按钮  -->

      <div class="owneraction" v-if="isOwner">
        <MainButton
          inactivated
          full
          extClass="hugewidth2"
          text="请去App操作"
          @onInvokeApp="onInvokeApp"
        />
      </div>
      <div v-else>
        <template v-if="canShowActionBar">
          <!--    批量类型 || 一口价 的 -->
          <template v-if="isBuyoutType">
            <action-product
              @onInvokeApp="onInvokeApp"
              :actionConfig="actionConfig"
            />
          </template>
          <template v-else>
            <!--  拍卖:出价&支付按钮  -->
            <action-bid
              @onInvokeApp="onInvokeApp"
              :actionConfig="actionConfig"
              @onRefreshTokenDetail="onRefreshTokenDetail"
            />
          </template>
        </template>
      </div>

      <!--      <action-bid-->
      <!--        :detailData="detailData"-->
      <!--        :tradeInfoData="tradeInfoData"-->
      <!--        :iAmMaxBidUser="iAmMaxBidUser"-->
      <!--        :actionConfig="actionConfig"-->
      <!--        :auctionInfo="auctionInfo"-->
      <!--        :contractPerformingConfig="contractPerformingConfig"-->
      <!--        @onRefreshTokenDetail="onRefreshTokenDetail"-->
      <!--      />-->

      <!--      &lt;!&ndash;    批量类型 || 一口价 的 &ndash;&gt;-->
      <!--      <action-product-->
      <!--        :detailData="detailData"-->
      <!--        :actionConfig="actionConfig"-->
      <!--        :contractPerformingConfig="contractPerformingConfig"-->
      <!--        @onInvokeApp="onInvokeApp"-->
      <!--      />-->
    </div>
    <div class="token-empty" v-if="loaded && resErrorType">
      <div
        class="token-empty-txt"
        v-html="
          PRODUCT_ERROR_REASON[isBuyoutType ? 'BUYOUT' : 'AUCTION'][
            resErrorType
          ]
        "
      />
      <DuButton
        type="recommend"
        v-if="productType !== 'COMMISSION'"
        size="small"
        @click="handleToTokenPage"
        >查看作品</DuButton
      >
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  provide,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import {
  Popup as VanPopup,
  Image as VanImage,
  showFailToast,
  showLoadingToast,
  showImagePreview,
  showSuccessToast,
  closeToast,
  NavBar as VanNavBar,
  Sticky as VanSticky,
} from "vant";
import NftApi from "@/apis";
import { DuButton } from "@frontend/dumpling";
import ProductApi from "@/apis/product";
import DownloadBanner from "@/components/DownloadBanner";
import MainButton from "@/components/MainButton";
import ActionBid from "./components/ActionBid.vue";
import ActionProduct from "./components/ActionProduct.vue";
import AuthorInfo from "./components/AuthorInfo.vue";
import OwnerActions from "./components/OwnerActions.vue";
import PrePurchaseTips from "@/components/PrePurchaseTips";
// import ProcessTips from "./components/ProcessTips.vue";
import CommentList from "@/components/CommentList/index.vue";
import BidList from "./components/BidList.vue";
import AuctionField from "./components/AuctionField.vue";
// import TokenOwnerList from "./components/TokenOwnerList.vue";
import SuspectNotice from "./components/SuspectNotice.vue";
import SuspectNoticeSp from "./components/SuspectNoticeSp.vue";
// import TokenNumber from "./components/TokenNumber.vue";
import BasicInfo from "./components/BasicInfo.vue";
import BasicInfoSp from "./components/BasicInfoSp.vue";
import ProductInfo from "./components/ProductInfo.vue";
import ProductInfoSp from "./components/ProductInfoSp.vue";
import { getQuery } from "@/utils/url";
import { getCountdownTime } from "@/utils/time";
import { invokeAppByYYB } from "@/utils/mob";
import { useUsersStore } from "@/store/user";
import {
  RESERVERPRICE_STAGE,
  AUCTION_BTN_STAGE,
  AUCTION_STAGE,
  QIANDAO_AUCTION_STAGE,
} from "@/constant/auction";
import { ART_TYPE, TRADE_STATUS } from "@/constant/contract";
import { useElementVisibility } from "@/hooks/useElementVisibility";
import { useTokenFactory } from "@/hooks/useTokenFactory";
import { emitter } from "@/utils/emitter";
import { PRODUCT_ERROR_REASON } from "@/constant/network";
import { PRODUCT_TYPE, TRADE_TYPE } from "@/constant/product";
// import HocBtn from "@/components/HocBtn";

const { generateActionConfig } = useTokenFactory();

// WAIT_BUYER_CONFIRM: "WAIT_BUYER_CONFIRM",

const userStore = useUsersStore();
const router = useRouter();
const route = useRoute();

const { userInfo, getIsLogin } = storeToRefs(userStore);

// const { isShowPrePurchasePopup } = useBtnAction();

const loaded = ref(false);
const bigTokenNoRef = ref();
const resErrorType = ref("");
const { isElementVisible } = useElementVisibility(bigTokenNoRef);
let productType = ref("TOKEN");
let originTradeType = ref("");
let detailData = ref({});
let buyoutDetailData = ref({});
let auctionDetailData = ref({});
let spDetailData = ref({});
let tradeInfoData = ref({ name: 666 });
let auctionInfo = ref({});
let actionConfig = ref({});
let myBidInfo = ref({});
let contractPerformingConfig = ref({}); // 履约信息
let bidList = ref();
let paintingsLogRef = ref();
let tokenOwnerListRef = ref();

const isBuyoutType = ref(true);
const tradeType = ref("buyout");
const tokenId = ref("");
const tradeId = ref(getQuery().tradeId);

// 是否是发布者(主态)
const isOwner = computed(() => {
  if (getIsLogin.value) {
    // if (isBuyoutType.value && "457052595526044934" === userInfo.value.userId) {
    if (
      isBuyoutType.value &&
      buyoutDetailData.value?.product?.sellerId === userInfo.value.userId
    ) {
      return true;
    } else if (auctionDetailData.value?.seller?.id === userInfo.value.userId) {
      return true;
    }
    return false;
  } else {
    return false;
  }
});

const isAuctionBidding = computed(() => {
  const auctionStatus = auctionDetailData.value?.statusEnum;
  return auctionStatus === QIANDAO_AUCTION_STAGE.AuctionStatus_Bidding;
});

const canShowActionBar = computed(() => {
  // const { tradeInfo } = detailData.value;
  const { tradeId } = getQuery();

  const auctionStatus = auctionDetailData.value?.statusEnum;
  const buyoutStatus = buyoutDetailData.value?.product?.status;

  // console.log('tradeInfo===', tradeInfo);
  // console.log('auctionStatus===', auctionStatus);

  return (
    (buyoutStatus === "ON_SALE" ||
      buyoutStatus === "OFF_SALE" ||
      buyoutStatus === "CLOSED" ||
      auctionStatus === QIANDAO_AUCTION_STAGE.AuctionStatus_Prepare ||
      auctionStatus === QIANDAO_AUCTION_STAGE.AuctionStatus_Bidding ||
      auctionStatus === QIANDAO_AUCTION_STAGE.AuctionStatus_Flowed ||
      auctionStatus === QIANDAO_AUCTION_STAGE.AuctionStatus_Close ||
      (iAmMaxBidUser.value &&
        auctionStatus ===
          QIANDAO_AUCTION_STAGE.AuctionStatus_WaitSellerConfirm)) &&
    tradeId
  );
});

const prePurchaseConfig = computed(() => ({
  currentStage: detailData.value?.purchaseInstructionType, // TODO use this
  creatorId: detailData.value?.publisher?.userId,
  watermaskImage: detailData?.value?.watermaskImages?.[0]?.url,
}));

const detailDataRef = computed(() => {
  return detailData.value;
});
const buyoutDetailDataRef = computed(() => {
  return buyoutDetailData.value;
});

const auctionDetailDataRef = computed(() => {
  return auctionDetailData.value;
});

const contractPerformingConfigRef = computed(() => {
  return contractPerformingConfig.value;
});

const spDetailDataRef = computed(() => {
  return spDetailData.value;
});

provide("prePurchaseConfig", prePurchaseConfig);
provide("detailData", detailDataRef);
provide("buyoutDetailData", buyoutDetailDataRef);
provide("auctionDetailData", auctionDetailDataRef);
provide("spDetailData", spDetailDataRef);
provide("contractPerformingConfig", contractPerformingConfigRef);
provide("bigTokenNoRef", bigTokenNoRef);
provide("tokenId", tokenId);
provide("tradeId", tradeId);
provide("tradeType", tradeType);
provide("isBuyoutType", isBuyoutType);

const inst = getCurrentInstance();

const iAmMaxBidUser = computed(() => {
  console.log("getIsLogin.value===", getIsLogin.value);
  if (getIsLogin.value) {
    return myBidInfo.value?.isLeader;
  }
  return false;
});

const onClickLeft = () => {
  router.back();
};

const handleToTokenPage = () => {
  router.push(`/token-detail?tokenId=${tokenId.value}`);
};

const goToProfilePage = () => {
  const { publisherUserId } = getQuery();
  if (!publisherUserId) {
    showFailToast("作品已删除");
    onInvokeApp();
    return;
  }
  invokeAppByYYB({
    targetPath: "profile",
    params: { userId: publisherUserId },
  });
};

const onInvokeApp = () => {
  const { tradeId } = getQuery();
  invokeAppByYYB({
    targetPath: "product",
    params: {
      tradeId,
    },
  });
};

const handleShowCovers = () => {
  const tempImgs = detailData.value.covers.map(
    (item) => item.url + "!mfit_w750_h750_jpg"
  );
  showImagePreview(tempImgs);
};

const onRefreshTokenDetail = () => {
  getTokenDetail();
  inst?.refs?.bidList?.initBidList();
  inst?.refs?.paintingsLogRef?.initLogs();
  inst?.refs?.tokenOwnerListRef?.initAllData();
};

const handleToOwnerList = () => {
  router.push(`/token-owner-list?tokenId=${detailData.value.originTokenId}`);
};

const formateTime = (time) => {
  return `${dayjs(+time).format("YYYY-MM-DD")}`;
};

const getTokenDetail = async () => {
  const { tradeId } = getQuery();

  const {
    tempProductType,
    tempTokenId,
    tempIsBuyoutType,
    tempTradeType,
    tempOriginTradeType,
    tempActionConfig,
    tempResErrorType,
    tempContractPerformingConfig,
    tempBuyoutDetailData,
    tempAuctionDetailData,
    tempMyBidInfo,
    // tempTradeInfo,
  } = await generateActionConfig({
    // isBuyoutType,
    iAmMaxBidUser,
    loaded,
    detailData,
    auctionInfo,
    userInfo,
    tradeInfoData,
    getIsLogin,
  });

  // tradeInfoData.value = tempTradeInfo;
  productType.value = tempProductType;
  originTradeType.value = tempOriginTradeType;
  tokenId.value = tempTokenId;
  isBuyoutType.value = tempIsBuyoutType;
  tradeType.value = tempTradeType;
  resErrorType.value = tempResErrorType;
  myBidInfo.value = tempMyBidInfo;
  actionConfig.value = tempActionConfig;
  contractPerformingConfig.value = tempContractPerformingConfig;
  buyoutDetailData.value = tempBuyoutDetailData;
  auctionDetailData.value = tempAuctionDetailData;

  if (originTradeType.value === TRADE_TYPE.C2C) {
    spDetailData.value = buyoutDetailData.value?.otherDetail?.standardProduct;
  } else if (originTradeType.value === TRADE_TYPE.AUCTION) {
    spDetailData.value = auctionDetailData.value?.standardProduct;
  }
  console.log("buyoutDetailData.value", buyoutDetailData.value);
  // console.log("detailData===", detailData);
};

const processQuery = async () => {
  const { productType, tradeId } = getQuery();
  if (productType === "SFT") {
    // 购买成功了, 回到了批发作品, 那么查询自己owner的是哪个
    showSuccessToast("您已拥有同款页面");
    const { data } = await NftApi.getOwnerList({
      tokenId: detailData.value.originTokenId,
      limit: 100,
      offset: 0,
      onlyMine: true,
    });

    console.log("data?.list?.[0]===", data?.list);

    const tempList = data?.list?.reverse();
    const tempId = tempList?.[0]?.id;

    setTimeout(() => {
      router.replace(
        `/product-detail?tokenId=${tempId}$tradeId=${tradeId}&tradeType=buyout`
      );
    }, 1500);
  }
};

getTokenDetail();

watch(
  () => route.query,
  (newParams, oldParams) => {
    if (newParams.tradeId !== oldParams.tradeId && newParams.tradeId) {
      console.log("tokenId has changed to:", newParams.tokenId);
      onRefreshTokenDetail();
    }
  }
);

watch(
  () => detailData.value,
  (newParams, oldParams) => {
    if (newParams.originTokenId) {
      processQuery();
    }
  },
  {
    deep: true,
  }
);

onMounted(() => {
  emitter.on("INIT_TOKEN_DETAIL", getTokenDetail);
});
</script>

<style lang="scss">
.page {
  font-size: 24rpx;
  position: relative;
  min-height: 100vh;
  padding-bottom: 36rpx;
  //background-color: #f8f8f8;
  background-color: white;
}
.page--withauction {
  padding-bottom: 260rpx;
  //padding-bottom: 1220rpx;
}
.main-img {
  position: relative;
  padding: 60rpx 60rpx 0 60rpx;
  width: 100%;
  overflow: hidden;
  &--value {
    width: 630rpx;
    //height: 630rpx;
    height: auto;
    border-radius: 48rpx;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.tags {
  display: flex;
  align-items: center;
  margin: 22rpx 0 16rpx 0;
  &-batch {
    height: 44rpx;
    padding: 0 8rpx;
    background: #7247dc;
    border-radius: 4rpx;
    line-height: 44rpx;
    font-weight: bold;
    font-size: 24rpx;
    color: #ffffff;
  }
  &-clothes {
    margin-left: 8rpx;
    padding: 0 8rpx;
    height: 44rpx;
    box-sizing: border-box;
    border: 2rpx solid #7247dc;
    border-radius: 4rpx;
    line-height: 44rpx;
    font-weight: bold;
    color: #7247dc;
  }
}
.head {
  padding: 0 30rpx;
  &-title {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin-top: 48rpx;
    text-align: left;
    &-cover {
      position: relative;
      margin-right: 16rpx;
      width: 144rpx;
      height: 144rpx;
      box-shadow: 8rpx 8rpx 16rpx rgba(0, 0, 0, 0.06);
      border-radius: 24rpx;
      overflow: hidden;
      //background-color: red;
      &-img {
        width: 144rpx;
        height: 144rpx;
      }
      &-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40rpx;
        height: 40rpx;
      }
    }
    &--name {
      width: 422rpx;
      font-weight: 800;
      font-size: 64rpx;
      line-height: 80rpx;
      color: #000000;
    }
    &--likecount {
      margin-left: auto;
      flex: 0 0 92rpx;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 92rpx;
      height: 92rpx;
      border-radius: 100%;
      box-shadow: 0px 8rpx 40rpx rgba(0, 0, 0, 0.06);
      background-color: #fff;
      img {
        width: 40rpx;
        height: 40rpx;
      }
      &--value {
        margin-top: 4rpx;
        font-weight: 900;
        font-size: 20rpx;
        line-height: 22rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &-id {
    margin-top: 18rpx;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 24rpx;
    //line-height: 36rpx;
    color: rgba(0, 0, 0, 0.5);
    &--icon {
      margin: 0 8rpx;
      width: 36rpx;
      height: 40rpx;
    }
    &--hash {
      display: flex;
      align-items: center;
      word-break: break-all;
      max-width: 600rpx;
    }
  }
  &-status {
    margin-top: 50rpx;
    display: flex;
    flex-direction: row;
    &--item {
      flex: 0 0 33.33%;
      text-align: left;
    }
    .item {
      &-label {
        font-size: 20rpx;
        line-height: 22rpx;
        color: #2a2c24;
      }
      &-value {
        margin-top: 4rpx;
        font-weight: 800;
        font-size: 32rpx;
        line-height: 48rpx;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }
  }
}
.section {
  padding: 0 30rpx;
  &-title {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 800;
    margin-top: 48rpx;
    font-size: 48rpx;
    line-height: 64rpx;
    color: #000000;
    &-subtitle {
      margin-left: 16rpx;
      font-weight: 400;
      font-size: 28rpx;
      line-height: 44rpx;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.content {
  margin-top: 28rpx;
  &-value {
    font-size: 28rpx;
    line-height: 44rpx;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
  }
}

.auction {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16rpx 30rpx 68rpx 30rpx;
  width: 100%;
  background-color: white;
  &-left {
    &--top {
      display: flex;
      align-items: center;
      .price {
        font-weight: 900;
        font-size: 40rpx;
        line-height: 52rpx;
        color: #000000;
      }
      .count {
        margin-left: 8rpx;
        font-size: 24rpx;
        line-height: 36rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &-right {
  }
}

.nav-back {
  width: 66rpx;
  height: 66rpx;
  border-radius: 100%;
  box-shadow: 0 8rpx 40rpx rgba(0, 0, 0, 0.06);
}

.ownerwrp {
  display: flex;
  align-items: center;
  height: 88rpx;
  overflow: hidden;
  .owner {
    margin-right: 8rpx;
    display: flex;
    &-avatar {
      width: 64rpx;
      height: 64rpx;
      flex-shrink: 0;
      object-fit: cover;
      border-radius: 100%;
      border: 2rpx solid rgba(0, 0, 0, 0.06);
    }
    &-content {
      text-align: left;
      margin-left: 16rpx;
      &-top {
        color: #000;
        font-size: 28rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 44rpx; /* 150% */
      }
      &-bottom {
        color: rgba(0, 0, 0, 0.5);
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 22rpx; /* 150% */
      }
    }
  }
}

.token-content {
}
.token-empty {
  margin: 30vh auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-txt {
    margin-bottom: 24rpx;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
}

.owneraction {
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 16rpx 30rpx 60rpx 30rpx;
}

.van-icon__image {
  width: 32rpx;
  height: 32rpx;
}
.page {
  --van-nav-bar-height: 88rpx;
  .van-nav-bar__title {
    max-width: 90%;
  }
}
//.van-image__img,
//.van-image-preview__image,
//img,
//.head-title-cover-img {
//  -webkit-touch-callout: none; /* 禁止弹出菜单 */
//  -webkit-user-select: none; /* 禁止选中文本 */
//  -khtml-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}

.binfo-wrp {
  margin-top: 24rpx;
  padding: 0 30rpx;
}

.pinfo-wrp {
  margin-top: 20rpx;
  padding: 0 30rpx;
}
</style>
