<!--
   Created by Terence on 2022/9/8 - 17:47
-->
<template>
  <div
    :class="`button ${full ? 'button--full' : ''} ${
      disabled ? 'button--disabled' : ''
    } ${inactivated ? 'button--inactivated' : ''} ${
      disabledType ? `button--disabled--${disabledType}` : ''
    } ${size} ${extClass}`"
    @click="handleClick"
    :style="{ ...extStyle }"
  >
    {{ text }}
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "normal",
  },
  full: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabledType: {
    type: String,
    default: "default",
  },
  inactivated: {
    type: Boolean,
    default: false,
  },
  extClass: {
    type: Object,
    default: () => ({}),
  },
  extStyle: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(["onInvokeApp"]);

const handleClick = () => {
  if (props.disabled) return;
  emits("onInvokeApp");
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 226rpx;
  height: 96rpx;
  font-weight: 600;
  font-size: 32rpx;
  line-height: 48rpx;
  letter-spacing: 0.01em;
  color: #ffffff;
  border-radius: 16rpx;
  background: linear-gradient(150.58deg, #2b1ac0 0%, #7247dc 100%);
}
.button--full {
  width: 100%;
}
.button--disabled {
  background: rgba(0, 0, 0, 0.3);
}
.button--disabled--temp {
  background: linear-gradient(150.58deg, #2b1ac0 0%, #7247dc 100%);
  opacity: 0.3;
}
.button--inactivated {
  background: #000;
}
.small {
  width: unset;
  height: unset;
  display: flex;
  padding: 14rpx 32rpx;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24rpx;
  font-style: normal;
  font-weight: 500;
  line-height: 36rpx; /* 150% */
}
.large {
  width: 354rpx;
}
</style>
