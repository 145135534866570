/**
 * Created by Terence on 2023/5/30 - 11:28
 * Description :
 */
import { ref, computed } from "vue";
import BidApi from "@/apis/bid";

export function useDeposit() {
  const aa = ref<any>(null);
  const userDeposit = ref<any>(null);
  const proportion = ref<any>(null);
  const bidInfo = ref<any>(null);

  const ratio = computed(() => 100 / +proportion.value?.percentage);

  const getAvailablePrice = async () => {
    const query = { bizId: "LIN_JIE" };
    // if (isLeading.value) {
    //   query.currentBidPrice = myBid.value?.autoBidPrice
    // }
    // if (res?.bidPrice) {
    //   query.bidPrice = res.bidPrice
    // }
    // if (res?.sellerPrice) {
    //   query.sellerPrice = res.sellerPrice
    // }
    const { data } = await BidApi.availablePrice(query);

    userDeposit.value = data;
  };

  // getBidInfo

  const getDepositProportion = async () => {
    const { data } = await BidApi.getDepositProportion({ bizId: "LIN_JIE" });
    proportion.value = data;
  };

  const getBidInfo = async (auctionId: string) => {
    const { data } = await BidApi.getBidInfo({ auctionId, bizId: "LIN_JIE" });
    bidInfo.value = data;
  };

  const initDeposits = async ({ auctionId }: { auctionId: string }) => {
    await Promise.all([
      getDepositProportion(),
      getAvailablePrice(),
      getBidInfo(auctionId),
    ]);
  };

  return {
    // proportion,
    ratio,
    userDeposit,
    bidInfo,
    initDeposits,
    getAvailablePrice,
    getDepositProportion,
  };
}
