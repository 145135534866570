<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div>
    <div class="auction" v-if="actionConfig.uiConfig">
      <confirm-tips :actionConfig="actionConfig" />
      <recharge-tips v-if="isShowRechargeTips" :detailData="detailData" />
      <div
        class="auction-tips"
        v-if="actionConfig.uiConfig.topTipsText"
        @click="handleTips"
      >
        <span class="auction-tips-label">
          {{ actionConfig.uiConfig.topTipsText }}
        </span>
        <van-icon name="question-o" />
      </div>

      <div class="auction-content">
        <!--    <div class="auction" v-if="actionConfig.isShowActionBar">-->
        <div
          class="auction-content-left"
          v-if="!actionConfig.uiConfig.endSummary"
        >
          <div class="auction-content-left--top">
            <span class="price">
              <span class="price-unit">¥</span>
              <span class="price-value">{{
                auctionDetailData?.currentPrice ||
                auctionDetailData?.startingPrice
              }}</span>
            </span>
          </div>
          <div class="auction-content-left--bottom" v-if="isShowBiddingTime">
            {{
              auctionDetailData?.isFutureSelling
                ? auctionDetailData?.startTimeString
                : auctionDetailData?.finishTimeString
            }}
            <span class="future-txt" @click="handleShowMorePopup"
              >{{ auctionDetailData?.isFutureSelling ? "开拍" : "截拍" }}
              <ArrowRight :direction="isShowSellingInfo ? 'down' : 'up'"
            /></span>
          </div>
          <div class="auction-content-left--bottom" v-if="isShowConfirmEndTime">
            {{ auctionDetailData?.confirmTimeString }} 前确认
          </div>
          <!--          <div class="auction-content-left&#45;&#45;bottom" v-else>已售空</div>-->
        </div>
        <div class="auction-content-endsummary" v-else>
          <div class="auction-content-endsummary-value">
            {{ actionConfig.uiConfig.endSummary }}
          </div>
        </div>
        <div class="auction-content-right">
          <!--        <main-button @onInvokeApp="handleOffer" text="购买" />-->
          <!--          <div class="auction-content-right-collect">-->
          <!--            <template v-if="actionConfig.uiConfig.showSubscribe">-->
          <!--              <Subscribe-->
          <!--                @onClickItem="onClickSubscribe"-->
          <!--                :isCollect="auctionDetailData?.isFollow"-->
          <!--              ></Subscribe>-->
          <!--            </template>-->
          <!--          </div>-->
          <main-button
            v-if="actionConfig.uiConfig.btnInfo.text"
            @onInvokeApp="() => handleClickBtn(actionConfig.uiConfig.btnInfo)"
            :disabled="actionConfig.uiConfig.btnInfo.disabled"
            :disabledType="actionConfig.uiConfig.btnInfo.disabledType"
            :inactivated="actionConfig.uiConfig.btnInfo.inactivated"
            :extClass="actionConfig.uiConfig.btnInfo.extClass"
            :text="actionConfig.uiConfig.btnInfo.text"
          />
        </div>
      </div>
    </div>

    <!--    竞价表单 弹窗  -->
    <van-popup
      v-model:show="showBidPopup"
      closeable
      position="bottom"
      lock-scroll
      close-icon="https://cdn.qiandaoapp.com/ued/d80d10918056cda4940aa8ccd6b01784.png"
      round
    >
      <div v-if="showBidPopup">
        <!--        :wasParticipate="wasParticipate"-->
        <bid-content
          :detailData="detailData"
          :auctionInfo="auctionInfo"
          :iAmMaxBidUser="iAmMaxBidUser"
          @onBidSuccess="onBidSuccess"
        />
      </div>
    </van-popup>

    <risk-warning-popup
      v-model="isShowRiskWarningPopup"
      @onConfirmed="handleConfirmRiskWarning"
    />

    <selling-info-popup
      v-model="isShowSellingInfo"
      :infos="sellingInfo"
      @onConfirmed="handleConfirmRiskWarning"
    />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import {
  Icon as VanIcon,
  Popup as VanPopup,
  showFailToast,
  showLoadingToast,
  showDialog,
  closeToast,
} from "vant";
import MainButton from "@/components/MainButton";
import BidContent from "./BidContent.vue";
// import BondContent from "./BondContent.vue";
import ConfirmTips from "./ConfirmTips.vue";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import BidApi from "@/apis/bid";
import { useUsersStore } from "@/store/user";
import { openPaymentWindow } from "@/utils/payment";
import { PRE_PAY_POPUP_TYPE, SPEND_MONEY_TYPE } from "@/constant/payment";
import {
  AUCTION_BTN_STAGE,
  QIANDAO_AUCTION_STAGE,
  RESERVERPRICE_STAGE,
} from "@/constant/auction";
import { invokeAppByYYB } from "@/utils/mob";
import { DuButton } from "@frontend/dumpling";
import { useBtnAction } from "@/hooks/useBtnAction";
import RiskWarningPopup from "@/components/RiskWarningPopup/index.vue";
import RechargeTips from "@/views/ProductDetail/components/RechargeTips.vue";
import Subscribe from "@/components/Subscribe/index.vue";
import ArrowRight from "@/components/ArrowRight/index.vue";
import SellingInfoPopup from "@/components/SellingInfoPopup/index.vue";
import { formatTimestamp } from "@/utils/time";
const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const {
  isShowRiskWarningPopup,
  invokeBtnAction,
  prePayAcl,
  invokePrePayConfirmed,
} = useBtnAction();

const props = defineProps({
  // detailData: {
  //   type: Object,
  //   required: true,
  // },
  actionConfig: {
    type: Object,
    required: true,
  },
  // contractPerformingConfig: {
  //   type: Object,
  //   required: true,
  // },
  auctionInfo: {
    type: Object,
    required: true,
  },
  iAmMaxBidUser: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onRefreshTokenDetail"]);

const auctionDetailData = inject("auctionDetailData");
const contractPerformingConfig = inject("contractPerformingConfig");
const detailData = inject("detailData");
const tokenId = inject("tokenId");

const wasParticipate = ref(false);
const isShowSellingInfo = ref(false);
const showBidPopup = ref(false);
// const showBidPopup = ref(true);
const showBonddPopup = ref(false);
const isShowRechargeTips = ref(false);

const isShowBiddingTime = computed(() => {
  if (
    auctionDetailData.value.statusEnum ===
      QIANDAO_AUCTION_STAGE.AuctionStatus_Bidding ||
    auctionDetailData.value.statusEnum ===
      QIANDAO_AUCTION_STAGE.AuctionStatus_Prepare
  ) {
    return true;
  } else {
    return false;
  }
});

const isShowConfirmEndTime = computed(() => {
  if (
    auctionDetailData.value.statusEnum ===
    QIANDAO_AUCTION_STAGE.AuctionStatus_WaitSellerConfirm
  ) {
    return true;
  } else {
    return false;
  }
});

const sellingInfo = computed(() => {
  return [
    formatTimestamp(+auctionDetailData.value?.startTime) + " 开拍",
    formatTimestamp(+auctionDetailData.value?.finishTime) + " 截拍",
  ];
});

const handleShowMorePopup = () => {
  isShowSellingInfo.value = !isShowSellingInfo.value;
};

const handleBid = async () => {
  // let tempWasParticipate = false;
  showLoadingToast({
    message: "加载中...",
    forbidClick: true,
  });
  try {
    // tempWasParticipate = await checkWasParticipate();
    // wasParticipate.value = tempWasParticipate;
    closeToast();
  } catch {
    closeToast();
    return;
  }

  showBidPopup.value = true;
};

const onClickSubscribe = () => {
  emit("onInvokeApp");
};

const handleClickBtn = (btnInfo) => {
  console.log("btnInfo", btnInfo);
  invokeBtnAction({
    actionConfig: props.actionConfig,
    handleBid,
    handlePay,
    handleEvokeAppForConfirmSale,
  });
};

const handleEvokeAppForConfirmSale = () => {
  invokeAppByYYB({
    targetPath: "token-detail",
    params: {
      tokenId: tokenId.value,
    },
  });
};

const handleTips = (e) => {
  e.stopPropagation();
  showDialog({
    title: "",
    message:
      "卖家在本次拍卖中设定了保留价。\n" +
      "截拍时，拍到保留价以上直接成交；\n" +
      "拍到保留价以下，卖家可选择出售或不出售，如果卖家选择出售但买家放弃支付，不会扣除买家保证金。",
  }).then(() => {
    // on close
  });
};

const handlePay = async () => {
  // const { contractPerformingConfig } = props;

  let tempCbUrl = "";
  if (contractPerformingConfig.value.isProgressivePayment) {
    tempCbUrl = window.location.href;
  }

  console.log("tempCbUrl===", tempCbUrl);

  prePayAcl({
    type: PRE_PAY_POPUP_TYPE.RISK_WARNING,
    callback: () => {
      return openPaymentWindow({
        // orderId: detailData?.value?.tradeInfo?.auctionInfo?.finalOrderId, // 去支付的时候finalOrderId
        orderId: auctionDetailData.value.orderId,
        cbUrl: tempCbUrl,
        cbUrlQuery: `spendMoneyType=${SPEND_MONEY_TYPE.PURCHASING}&tokenId=${tokenId.value}`,
      });
    },
  });
};

const onBidSuccess = (useFree) => {
  if (useFree) {
    isShowRechargeTips.value = true;
    setTimeout(() => {
      isShowRechargeTips.value = false;
    }, 5000);
  }
  showBidPopup.value = false;
  // getTokenDetail();
  emit("onRefreshTokenDetail");
};

// const checkWasParticipate = () => {
//   // eslint-disable-next-line no-async-promise-executor
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await BidApi.checkWasParticipate({
//         auctionId: props.detailData?.tradeInfo?.tradeOrderId,
//       });
//       if (res.code !== REQUEST_OK) {
//         return showFailToast("查询参拍失败");
//       }
//       resolve(res.data.isParticipate);
//     } catch (e) {
//       reject();
//       console.error("checkWasParticipate::error", e);
//       showFailToast("查询参拍失败");
//     }
//   });
// };

const handleConfirmRiskWarning = () => {
  invokePrePayConfirmed({ type: PRE_PAY_POPUP_TYPE.RISK_WARNING });
};
</script>

<style lang="scss" scoped>
.auction {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16rpx 30rpx 68rpx 30rpx;
  width: 100%;
  background-color: white;
  &-tips {
    display: flex;
    align-items: center;
    width: 100%;
    &-label {
      margin-right: 8rpx;
      padding: 12rpx 0;
      font-size: 24rpx;
    }
  }
  &-content {
    display: flex;
    width: 100%;
    //align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      flex-direction: column;
      //align-items: center;
      justify-content: center;
      &--top {
        display: flex;
        align-items: center;
        .price {
          display: flex;
          align-items: baseline;
          &-unit {
            color: var(---color_third, #7247dc);
            text-align: center;
            font-family: "Roboto";
            font-size: 32rpx;
            font-style: normal;
            font-weight: 700;
            line-height: 48rpx; /* 150% */
          }
          &-value {
            color: var(---color_third, #7247dc);
            text-align: center;
            font-family: "Roboto";
            font-size: 40rpx;
            font-style: normal;
            font-weight: 700;
            line-height: 52rpx; /* 130% */
          }
        }
        .count {
          margin-left: 8rpx;
          font-size: 24rpx;
          line-height: 36rpx;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      &--bottom {
        font-size: 24rpx;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      &-collect {
        margin-right: 32rpx;
      }
    }
    &-endsummary {
      text-align: center;
      width: 100%;
    }
  }
}
.hugewidth600 {
  width: 600rpx;
}
.hugewidth {
  width: 354rpx;
}
.hugewidth2 {
  width: 394rpx;
}
</style>

<!--v-model	min max step-->
