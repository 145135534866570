<template>
  <div class="content-wrp">
    <slot />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.content-wrp {
  padding: 32rpx 24rpx;
  background: #ffffff;
  box-shadow: 0px 8rpx 40rpx rgba(0, 0, 0, 0.06);
  border-radius: 32rpx;
}
</style>
