/**
 * Created by Terence on 2023/2/15 - 21:15
 * Description :
 */

export const ART_TYPE: any = {
  DEFAULT: "DEFAULT",
  CHARACTER: "CHARACTER",
  CREATURE: "CREATURE",
  CLOTHING: "CLOTHING",
  SPACE_FOR_ARTWORK: "SPACE_FOR_ARTWORK",
  WATERMARK: "WATERMARK",
  OTHER: "TEMPLATE",
  TEMPLATE: "TEMPLATE",
  TEXT: "TEXT",
};

export const ART_TYPE_MAP: any = {
  [ART_TYPE.DEFAULT]: "默认",
  [ART_TYPE.CHARACTER]: "人设",
  [ART_TYPE.CREATURE]: "兽设",
  [ART_TYPE.CLOTHING]: "服设",
  [ART_TYPE.SPACE_FOR_ARTWORK]: "稿位",
  [ART_TYPE.WATERMARK]: "水印",
  [ART_TYPE.OTHER]: "其他",
  [ART_TYPE.TEMPLATE]: "模板",
  [ART_TYPE.TEXT]: "文设",
};

export const TRADE_STATUS: any = {
  NO_TRADE: "NO_TRADE",
  SELLING: "SELLING",
  FUTURE_SELLING: "FUTURE_SELLING",
  WAIT_BUYER_CONFIRM: "WAIT_BUYER_CONFIRM",
  SOLD_OUT: "SOLD_OUT",
};
