<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="welfare-tips">
    <span class="welfare-tips-prefix"
      >此商品为临界向活跃用户提供的专属福利，请阅读</span
    >
    <span class="welfare-tips-entry" @click="handleShowInfo">《购前需知》</span>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import ArrowRight from "@/components/ArrowRight";
import { invokeAppByYYB } from "@/utils/mob";

const props = defineProps({
  actionConfig: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(["onShowInfo"]);

const handleShowInfo = () => {
  emits("onShowInfo");
};
</script>

<style lang="scss" scoped>
.welfare-tips {
  padding: 16rpx 0;
  background-color: #fff;
  &-prefix {
    color: rgba(0, 0, 0, 0.5);
    font-size: 24rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 36rpx; /* 150% */
  }
  &-entry {
    color: #fc9249;
    font-size: 24rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 36rpx; /* 150% */
  }
}
</style>

<!--v-model	min max step-->
