<!--
   Created by Terence on 2024/3/7 - 17:13
-->
<template>
  <div class="binfo">
    <div class="binfo-imgs">
      <van-swipe class="sp-wrp" :autoplay="0" indicator-color="#7247DC">
        <van-swipe-item
          v-for="(item, index) in spDetailData?.attachments"
          :key="index"
          class="sp-item"
        >
          <img :src="item + '!mfit_w750_h750_jpg'" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="binfo-title">{{ spDetailData.name }}</div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import { Swipe as VanSwipe, SwipeItem as VanSwipeItem } from "vant";
import TokenPreviewSection from "./TokenPreviewSection";
// import TokenNumber from "./TokenNumber.vue";
import { useElementVisibility } from "@/hooks/useElementVisibility";
import ArrowRight from "@/components/ArrowRight/index.vue";
import { useRouter } from "vue-router";
import NftApi from "@/apis";

const router = useRouter();

const spDetailData = inject("spDetailData");
const tokenId = inject("tokenId");

const emit = defineEmits(["onInvokeApp"]);
</script>

<style lang="scss" scoped>
.binfo {
  position: relative;
  //padding-bottom: 32rpx;
  border-radius: 32rpx;
  //background: #fff;
  //box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;

  &-title {
    color: #0d002d;
    font-family: "PingFang SC";
    font-size: 32rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 48rpx; /* 150% */
  }
  .sp-wrp .sp-item img {
    width: 100%;
    display: inline-block;
    aspect-ratio: 1;
    object-fit: cover;

    border-radius: 32rpx;
  }

  .showact {
    position: absolute;
    bottom: 16rpx;
    right: 30rpx;
    display: flex;
    padding: 6rpx 20rpx;
    justify-content: center;
    align-items: center;
    color: var(--v-10-main-white-09, #fff);
    font-size: 24rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 36rpx; /* 150% */
    border-radius: 8rpx;
    background: var(---button_info_color, rgba(0, 0, 0, 0.5));
  }

  .token-preview-section-wrp {
    padding: 0 32rpx;
  }
}
</style>
