<!--
   Created by Terence on 2024/3/7 - 17:13
-->
<template>
  <div class="pinfo">
    <product-price-info />
    <div class="pinfo-desc" v-if="buyoutDetailData.product?.remark">
      <!--      <div class="section-title">作品描述</div>-->
      <div class="pinfo-desc-value">{{ buyoutDetailData.product?.remark }}</div>
    </div>

    <div class="pinfo-desc" v-if="spDetailData?.description">
      <!--      <div class="section-title">作品描述</div>-->
      <div class="pinfo-desc-value">{{ spDetailData?.description }}</div>
    </div>

    <div class="pinfo-reqs" v-if="localSpConfig">
      <div class="pinfo-reqs-item">
        <div class="label">授权范围</div>
        <div class="value">
          {{
            transConfigEnum(
              spDetailData?.commissionInfo?.scopeOfAuthorization,
              "authorizationScopes"
            )
          }}
        </div>
      </div>
      <div class="pinfo-reqs-item">
        <div class="label">发布权限</div>
        <div class="value">
          {{
            transConfigEnum(
              spDetailData?.commissionInfo?.publishPermission,
              "permissions"
            )
          }}
        </div>
      </div>
      <div class="pinfo-reqs-item">
        <div class="label">交付格式</div>
        <div class="value">
          {{
            transConfigEnum(
              spDetailData?.commissionInfo?.deliveryFormat,
              "formats"
            )
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import TokenPreviewSection from "./TokenPreviewSection";
// import TokenNumber from "./TokenNumber.vue";
import { useElementVisibility } from "@/hooks/useElementVisibility";
import ProductPriceInfo from "./ProductPriceInfo.vue";
import NftApi from "@/apis";

const detailData = inject("detailData");
const buyoutDetailData = inject("buyoutDetailData");
const auctionDetailData = inject("auctionDetailData");
// const tradeType = inject("tradeType");
const isBuyoutType = inject("isBuyoutType");
const bigTokenNoRef = inject("bigTokenNoRef");
const spDetailData = inject("spDetailData");

const localSpConfig = ref("");

const transConfigEnum = (val, key) => {
  const targetEnum = localSpConfig.value[key];
  return targetEnum.filter((item) => item.id === val)[0].name;
};

const initConfig = async () => {
  const { data: spConfig } = await NftApi.getSpPublishConfig();
  const { publishConfig } = spConfig || {};

  localSpConfig.value = publishConfig;

  console.log("spConfig===", spConfig);
};

onMounted(() => {
  initConfig();
});

// const bigTokenNoRef = ref();
</script>

<style lang="scss" scoped>
.pinfo {
  //border-radius: 32rpx;
  //overflow: hidden;
  &-values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      .price {
        margin-right: 16rpx;
        display: flex;
        align-items: baseline;
        &-suffix {
          color: var(---color_third, #7247dc);
          font-family: "Roboto";
          font-size: 36rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 50rpx; /* 138.889% */
        }
        &-value {
          margin-left: 4rpx;
          color: var(---color_third, #7247dc);
          font-family: "Roboto";
          font-size: 48rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 64rpx; /* 133.333% */
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    .count {
      color: var(---text_third, rgba(0, 0, 0, 0.5));
      font-size: 24rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 36rpx; /* 150% */
      white-space: nowrap;
      &-label,
      &-value {
        display: inline-block;
      }
    }
  }
  &-desc {
    margin-top: 16rpx;
    &-value {
      color: var(---text_secondary, rgba(0, 0, 0, 0.7));
      font-size: 28rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 44rpx; /* 157.143% */
    }
  }

  .splitline {
    display: inline-block;
    margin: 0 16rpx;
    width: 2rpx;
    height: 24rpx;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &-reqs {
    margin-top: 16rpx;
    &-item {
      display: flex;
      align-items: center;
      margin-top: 16rpx;
      .label {
        color: var(--v-10-main-neural-06, rgba(0, 0, 0, 0.4));
        font-size: 28rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 44rpx; /* 157.143% */
      }
      .value {
        margin-left: 16rpx;
        color: var(---text_secondary, rgba(0, 0, 0, 0.7));
        font-size: 28rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 44rpx; /* 157.143% */
      }
    }
  }
}
</style>
