<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="section">
    <div class="section-title">评论</div>
    <div class="enter" @click="handleJump" v-if="resultList.length">
      <van-image
        fit="cover"
        class="enter-avatar"
        :src="userInfo.avatarUrl || DEFAULT_AVATAR + '!mfit_w480_h480_jpg'"
        alt=""
      />
      <div class="enter-tips">{{ INPUT_PLACEHOLDER }}</div>
    </div>
    <div class="content" v-if="resultList.length">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="useExtList ? '' : '没有更多了'"
        :immediate-check="false"
        @load="loadMoreFc"
      >
        <div
          class="content-item"
          v-for="(item, index) in resultList"
          :key="index"
        >
          <div class="content-item-left">
            <van-image
              fit="cover"
              class="avatar"
              :src="item.creator.avatar + '!mfit_w480_h480_jpg'"
              alt=""
            />
          </div>
          <div class="content-item-right">
            <div class="name">{{ item.creator.nickName }}</div>
            <div class="comment">
              <span class="replay" v-if="item.replyToUser">
                <span class="replay-suffix">回复&nbsp;</span>
                <span class="replay-name" @click="handleJump"
                  >@{{ item.replyToUser.nickName }}：</span
                >
              </span>
              {{ item.content }}
            </div>
            <div class="info">
              <span class="info-time">{{ item.timeString }}</span>
              <span class="info-region" v-if="item.regionInfo?.province?.name"
                >&nbsp;·&nbsp;{{ item.regionInfo.province.name }}</span
              >
            </div>
          </div>
        </div>
      </van-list>
      <div v-if="useExtList" class="ext-list-showall" @click="handleJump">
        查看全部&nbsp;{{ extListCount }}&nbsp;条评论
        <ArrowRight :style="{ marginLeft: '0.5333333333333333rem' }" type="primary" />
      </div>
    </div>
    <div class="content" v-else @click="handleJump">
      <div class="content-empty">
        暂无评论，<span class="textbtn">点击抢首评٩(•̤̀ᵕ•̤́๑)ᵎᵎᵎᵎ</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { Image as VanImage, List as VanList } from "vant";
import { time } from "@frontend/echo-utils";
import dayjs from "dayjs";
import ArrowRight from "@/components/ArrowRight/index.vue";
import NftApi from "@/apis";
import { DEFAULT_AVATAR } from "@/constant/user";
import { getQuery } from "@/utils/url";
import { useUsersStore } from "@/store/user";
import { invokeAppByYYB } from "@/utils/mob";
import { timeDiff } from "@/utils/time";
import { PRODUCT_TYPE } from "@/constant/product";

const INPUT_PLACEHOLDER = "文明发言，保持好心情(>ω< )";

const userStore = useUsersStore();
const { userInfo } = storeToRefs(userStore);

const tokenId = inject("tokenId");
const tradeId = inject("tradeId");
const tradeType = inject("tradeType");

const props = defineProps({
  collectionData: {
    type: Object,
    required: true,
  },
  tokenId: {
    type: String,
    required: true,
  },
  postId: {
    type: String,
    default: "",
  },
  productType: {
    type: String,
    default: "",
  },
  originTradeType: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "token",
  },
  useExtList: {
    type: Boolean,
    default: false,
  },
  extListCount: {
    type: Number,
    default: 0,
  },
  maxCount: {
    type: Number,
    default: 0,
  },
  extList: {
    type: Array,
    default: () => [],
  },
});

const listData = ref([]);
// const isExpand = ref(false);
const loading = ref(false);
const finished = ref(false);
const nextFlag = ref("");

// const handleExpand = () => {
//   isExpand.value = true;
// };

const resultList = computed(() => {
  const tempList = props.extList;
  return tempList?.length
    ? tempList?.splice(0, props.maxCount)
    : listData.value;
  // return listData.value;
});

const handleJump = () => {
  const { tokenId: queryTokenId, postId } = getQuery();

  const tempTokenId = queryTokenId || tokenId.value;

  if (props.type === "suspect") {
    invokeAppByYYB({
      targetPath: "token/suspect/detail",
      params: {
        tokenId: tempTokenId,
        topId: props.postId,
      },
    });
  } else if (props.type === "post") {
    invokeAppByYYB({
      targetPath: "community/post/detail",
      params: {
        id: postId,
      },
    });
  } else if (tradeId.value && tradeType.value) {
    // product页面
    invokeAppByYYB({
      targetPath: "product",
      params: {
        tokenId: tempTokenId,
        tradeId: tradeId.value,
        tradeType: tradeType.value,
      },
    });
  } else {
    invokeAppByYYB({
      targetPath: "token-detail",
      params: {
        tokenId: tempTokenId,
      },
    });
  }
};

const initList = async ({ isReset } = { isReset: false }) => {
  const { tokenId: queryTokenId } = getQuery();

  const tempTokenId = props.tokenId || queryTokenId || tokenId?.value;
  if (props.useExtList) {
    finished.value = true;
    return;
  }

  loading.value = true;

  let resData = {};
  let tempList = [];

  if (props.type === "post") {
    const tempParams = {
      postId: props.postId,
      limit: 10,
    };
    if (listData.value?.length && !isReset) {
      tempParams.cursor = listData.value[listData.value?.length - 1];
    }
    const res = await NftApi.getCommentListByPostId(tempParams);
    resData = res?.data || {};
    tempList = res?.data?.list || [];
  } else if (props.productType === PRODUCT_TYPE.COMMISSION) {
    const res = await NftApi.getCommentList(tradeId.value, {
      limit: 10,
      // offset: isReset ? 0 : listData.value.length,
      next: isReset ? "" : nextFlag.value,
      app: "LINJIE",
      entityType: props.originTradeType,
    });
    resData = res || [];
    tempList = res?.data || [];
  } else {
    const res = await NftApi.getCommentList(tempTokenId, {
      limit: 10,
      // offset: isReset ? 0 : listData.value.length,
      next: isReset ? "" : nextFlag.value,
      app: "LINJIE",
      entityType: props.originTradeType,
    });

    resData = res || [];
    tempList = res?.data || [];
  }

  loading.value = false;

  let linjieAccountInfoMap = undefined;
  if (tempList.length) {
    const userIds = [];
    tempList.forEach((item) => {
      item.creator.id = item.creator.userId;
      if (item.creator?.id) {
        userIds.push(item.creator.id);
      }
      if (item.replyToUser?.id) {
        userIds.push(item.replyToUser.id);
      }
    });
    const accountRes = await NftApi.getAccountInfo({ userIds: userIds });
    linjieAccountInfoMap = new Map(Object.entries(accountRes.data));
  }

  if (linjieAccountInfoMap) {
    tempList.forEach((item) => {
      if (linjieAccountInfoMap.has(item.creator.id)) {
        item.creator.avatar = linjieAccountInfoMap.get(
          item.creator.id
        ).avatarUrl;
        item.creator.nickName = linjieAccountInfoMap.get(
          item.creator.id
        ).displayName;
      }
      if (item.replyToUser?.id) {
        item.replyToUser.avatar = linjieAccountInfoMap.get(
          item.replyToUser.id
        ).avatarUrl;
        item.replyToUser.nickName = linjieAccountInfoMap.get(
          item.replyToUser.id
        ).displayName;
      }
    });
  }

  tempList.forEach((item) => {
    let timeString = timeDiff(+new Date(item.createdAt));
    item.timeString = timeString;
  });

  console.log("tempList===", tempList);

  if (isReset) {
    listData.value = tempList;
  } else {
    listData.value = listData.value.concat(tempList);
  }
  finished.value = !resData?.more;
  nextFlag.value = resData?.next;
  // const tempList = res.data?.list || [];
  // logList.value = tempList.map((item) => {
  //   item.timeString = dayjs(+item.time).format("YYYY-MM-DD");
  //   return item;
  // });
};

const loadMoreFc = () => {
  initList();
};

onMounted(() => {
  console.log("props.useExtList", props.useExtList);
  !props.useExtList && initList();
});
</script>

<style lang="scss" scoped>
.ext-list-showall {
  display: flex;
  align-items: center;
  padding-left: 72rpx;
  color: #7247dc;
  font-size: 24rpx;
  font-style: normal;
  font-weight: 500;
  line-height: 36rpx; /* 150% */
}

.enter {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 16rpx 32rpx;
  background: #fff;
  margin: 24rpx auto 0 auto;
  box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
  &-avatar {
    width: 56rpx;
    height: 56rpx;
    border-radius: 100%;
    overflow: hidden;
    border: 2rpx solid rgba(0, 0, 0, 0.05);
  }
  &-tips {
    margin-left: 16rpx;
    color: rgba(0, 0, 0, 0.5);

    /* Regular/B6 */
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 22px;
  }
}

.content {
  margin-top: 28rpx;
  display: flex;
  flex-direction: column;
  width: 690rpx;
  padding: 32rpx;
  border-radius: 32rpx;
  background: #fff;
  box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
  &-item {
    display: flex;
    margin-bottom: 40rpx;
    &-left {
      .avatar {
        width: 56rpx;
        height: 56rpx;
        border-radius: 100%;
        overflow: hidden;
        border: 1rpx solid rgba(0, 0, 0, 0.05);
      }
    }
    &-right {
      margin-left: 16rpx;
      .name {
        color: rgba(0, 0, 0, 0.7);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 36rpx;
      }
      .comment {
        margin-top: 8rpx;
        color: #000;
        font-size: 28rpx;
        font-weight: 400;
        line-height: 44fpx;
        .replay {
          &-suffix {
            color: rgba(0, 0, 0, 0.5);
          }
          &-name {
            color: #7247dc;
          }
        }
      }
      .info {
        margin-top: 8rpx;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20rpx;
        font-weight: 400;
        line-height: 22rpx;
      }
    }
  }

  &-empty {
    height: 160rpx;
    line-height: 160rpx;
    font-size: 28rpx;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    .textbtn {
      color: #7247dc;
    }
  }
}
</style>

<!--v-model	min max step-->
