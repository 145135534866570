<!--
   Created by Terence on 2024/2/21 - 17:00
-->
<!--eslint-disable-->
<template>
  <div class="rechargepop">
    <van-popup
      v-model:show="modelValue"
      @close="handleClose"
      closeable
      position="bottom"
      lock-scroll
      close-icon=""
      round
    >
      <div class="rechargepop-content">
        <div class="title">
          <div class="title-main">{{isDefaultRecharge ? '保证金充值' : '保证金不足，请充值'}}</div>
          <div class="title-sub" v-if="!isDefaultRecharge" @click="toMyDepositAccountPage">
            我的保证金账户
            <ArrowRight :style="{ marginLeft: '0.26666666666666666rem' }" />
          </div>
        </div>
        <div class="tips">保证金余额适用于所有拍品，未冻结部分可随时提取</div>
        <div class="balance">
          保证金余额：<strong>¥{{ userDeposit?.availablePrice }}</strong>
          <span v-if="depositChargeGap"
            >(还差 <strong>¥{{ depositChargeGap }}</strong
            >)</span
          >
          <span class="balance-detail" @click="goToDetailPage">
            明细 <ArrowRight :style="{ marginLeft: '0.26666666666666666rem' }" type="primary" />
          </span>
        </div>
        <div class="opts">
          <div
            v-for="(item, index) in paymentList"
            :key="index"
            :class="`opts-item ${
              rechargeValue === item ? 'opts-item--active' : ''
            }`"
            @click="handleSelectOpts(item)"
          >
            ¥{{ item }}
          </div>
        </div>
        <div class="balance-biggest" @click="handleRechargeRules">
          充值后累计可出价&nbsp;<strong
            >¥{{
              (userDeposit?.availablePrice + rechargeValue) * depositRatio
            }}</strong
          >
          <img
            src="https://cdn.qiandaoapp.com/interior/images/21140ed0a4880058d707d6bb2e7069b5.png"
            alt=""
          />
        </div>
        <div class="submit">
          <div class="submit-btn">
            <van-button
              type="primary"
              size="large"
              :disabled="disableSubmit"
              @click="handleSubmit"
            >
              立即充值 ¥{{ rechargeValue }}
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>

    <deposit-recharge-rules-dialog v-model="isShowRechargeRules" />
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  defineProps,
  defineEmits,
  onMounted,
  inject,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import { Popup as VanPopup, Button as VanButton } from "vant";
import ArrowRight from "@/components/ArrowRight/index.vue";
import WalletApi from "@/apis/wallet";
import { invokeAppByYYB } from "@/utils/mob";
import { openPaymentWindow } from "@/utils/payment";
import { SPEND_MONEY_TYPE } from "@/constant/payment";
import DepositRechargeRulesDialog from "@/components/DepositRechargeRulesDialog/index.vue";

const router = useRouter();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  userDeposit: {
    type: Object,
    default: () => ({}),
  },
  depositRatio: {
    type: Number,
    default: 0,
  },
  depositBidPrice: {
    type: Number,
    default: 0,
  },
  rechargeType: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:modelValue", "onConfirmed"]);

const prePurchaseConfig = inject("prePurchaseConfig");
const tokenId = inject("tokenId");

const rechargeValue = ref(1);
const paymentList = ref([1, 5, 10, 20, 50, 100]);
const isShowRechargeRules = ref(false);

const isDefaultRecharge = computed(() => {
  return props.rechargeType === "defaultRecharge";
});

const depositChargeGap = computed(() => {
  if (props.depositBidPrice - props.userDeposit?.availableBidPrice > 0) {
    return (
      Math.abs(props.depositBidPrice - props.userDeposit?.availableBidPrice) /
      props.depositRatio
    );
  } else {
    return 0;
  }
});

watch(
  [() => props.modelValue, () => depositChargeGap.value],
  (newParams, oldParams) => {
    if (newParams) {
      const arr = paymentList.value;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] >= depositChargeGap.value) {
          rechargeValue.value = arr[i];
          return;
        }
      }

      // 如果所有元素都小于n，则返回数组中的最后一个元素（即最大元素）
      rechargeValue.value = arr[arr.length - 1];
    }
  },
  {
    deep: true,
  }
);

const handleClose = () => {
  emit("update:modelValue", false);
};

const handleSubmit = async () => {
  const {
    data: { orderId },
  } = await WalletApi.createToUserDeposit(rechargeValue.value);
  // const query = {
  //   paySuccessId: props.paySuccessId,
  //   orderId,
  //   description: '充值保证金',
  // }

  openPaymentWindow({
    orderId,
    // paySuccessId:`deposit${auctionId}`,
    description: "充值保证金",
    cbUrl: window.location.href,
    cbUrlQuery: `spendMoneyType=${SPEND_MONEY_TYPE.RECHARGE_DEPOSIT}&tokenId=${tokenId.value}`,
  });
};

const handleSelectOpts = (item) => {
  rechargeValue.value = item;
};

const goToDetailPage = () => {
  invokeAppByYYB({
    targetPath: "pay/wallet/security-deposit/freezed-flow",
    params: {},
  });
};

const toMyDepositAccountPage = () => {
  router.push(`/wallet`);
};

const handleRechargeRules = () => {
  isShowRechargeRules.value = true;
};

onMounted(() => {
  console.log(1111);
  //
});
</script>

<style lang="scss">
.rechargepop {
  &-content {
    padding: 30rpx;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-main {
        color: #000;
        font-size: 32rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 48rpx; /* 150% */
      }
      &-sub {
        color: rgba(0, 0, 0, 0.5);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 36rpx; /* 150% */
      }
    }
    .tips {
      margin-top: 24rpx;
      color: rgba(0, 0, 0, 0.5);
      font-size: 24rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 36rpx; /* 150% */
    }
    .balance {
      margin-top: 40rpx;
      font-size: 24rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 36rpx; /* 150% */
      &-detail {
        color: #7247dc;
      }
      &-biggest {
        margin-top: 36rpx;
        display: flex;
        align-items: center;
        img {
          margin-left: 8rpx;
          width: 24rpx;
          display: block;
        }
      }
    }
  }
  .opts {
    margin-top: 22rpx;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32rpx;
    row-gap: 32rpx;
    padding: 0;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18rpx 24rpx;
      border-radius: 8rpx;
      color: rgba(0, 0, 0, 0.7);
      font-family: "Roboto";
      font-size: 28rpx;
      font-weight: 500;
      line-height: 44rpx; /* 157.143% */
      background: #f8f8f8;
    }
    &-item--active {
      color: #7247dc;
      background: var(
        ---bg_primary,
        linear-gradient(
          151deg,
          rgba(43, 26, 192, 0.05) 0%,
          rgba(114, 71, 220, 0.05) 100%
        )
      );
    }
  }
  .submit {
    margin: 16rpx auto 0 auto;
    //padding-bottom: 68rpx;
    width: 100%;
    height: calc(128rpx + 68rpx);
    background: #ffffff;
    overflow: hidden;
    &-btn {
      margin: 16rpx auto 0 auto;
      width: 290rpx;
    }
  }
}
:root:root {
  --van-button-border-width: 0;
  --van-popup-background: #f8f8f8;
}
</style>
