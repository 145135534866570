/**
 * Created by Terence on 2023/2/15 - 21:15
 * Description :
 */
export const PRODUCT_TYPE = {
  COMMISSION: "COMMISSION", // 稿位
  TOKEN: "TOKEN", // 作品
};

export const TRADE_TYPE = {
  C2C: "C2C", // 一口价
  AUCTION: "AUCTION", // 拍卖
};
