export class EventEmitter {
  listeners = {};

  emit(name, payload) {
    const set = this.listeners[name] || [];
    set.forEach((fn) => {
      fn(payload);
    });
  }

  on(name, fn) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    const set = this.listeners[name];
    if (set.indexOf(fn) < 0) {
      set.push(fn);
    }

    return () => {
      const set = this.listeners[name] || [];
      set.filter((f) => f !== fn);
    };
  }

  off(name, fn) {
    const set = this.listeners[name];
    if (set?.length) {
      let idx = -1;
      set.forEach((item, index) => {
        if (item === fn) {
          idx = index;
        }
      });
      if (idx >= 0) {
        set.splice(idx, 1);
      }
    }
  }
}

export const emitter = new EventEmitter();
