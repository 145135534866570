<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="confirm-tips" v-if="actionConfig.uiConfig.waitConfirmInAppText">
    <div @click="handleEvokeAppForConfirmSale" class="confirm-tips-label">
      {{ actionConfig.uiConfig.waitConfirmInAppText }}
      <ArrowRight :style="{ marginLeft: '2.666666666666667rem' }" type="primary" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject } from "vue";
import ArrowRight from "@/components/ArrowRight";
import { invokeAppByYYB } from "@/utils/mob";

const props = defineProps({
  actionConfig: {
    type: Object,
    required: true,
  },
});

const tokenId = inject("tokenId");
const tradeId = inject("tradeId");
const tradeType = inject("tradeType");

const handleEvokeAppForConfirmSale = () => {
  invokeAppByYYB({
    targetPath: "product",
    params: {
      tokenId: tokenId.value,
      tradeId: tradeId.value,
      tradeType: tradeType.value,
    },
  });
};
</script>

<style lang="scss" scoped>
.confirm-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 88rpx;
  line-height: 88rpx;
  padding: 16rpx 30px;
  &-label {
    margin-right: 8rpx;
    font-size: 28rpx;
    line-height: 44rpx;
    font-weight: 500;
    color: #2b1ac0;
  }
}
</style>

<!--v-model	min max step-->
