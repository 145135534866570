<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="bid">
    <div class="top-wrp">
      <div class="bid-img">
        <van-image
          class="bid-img-content"
          fit="cover"
          :src="detailData.imageUrl + '!mfit_w750_h750_jpg'"
          alt=""
        />
      </div>
      <div class="bid-title">{{ detailData.name }}</div>
      <div v-if="wasParticipate" class="bid-joined-tips">
        你参与过本竞拍，继续更新出价
      </div>
      <div class="bid-switch">
        <tab-switch :tabs="tabs" @onClickItem="onClickSwitch" />
      </div>
      <div class="bid-current">
        <div v-if="currentSwitchTab.type === 'manual'" class="bid-current-tips">
          直接以<span class="bid-current-tips-value">¥{{ price }}</span
          >出价一次
        </div>
        <div v-else class="bid-current-tips">
          将在<span class="bid-current-tips-value">¥{{ price }}</span
          >内自动竞价
        </div>
      </div>
      <div class="bid-stepper">
        <!--        :initValue="initPrice"-->
        <stepper
          :initValue="price"
          :min="minPrice"
          :max="1000000"
          :step="stepPrice"
          v-model="price"
        />
      </div>
      <div class="bid-rule">
        查看
        <a
          target="_blank"
          href="https://config-cdn.echoing.tech/docs/#/web3-auction-rules"
          >《竞价规则》</a
        >
      </div>
      <div class="bid-info">
        <span class="bid-info-prefix">{{
          isFirstBid ? "起拍价" : "当前价"
        }}</span>
        <span class="bid-info-value"
          >¥ {{ auctionDetailData?.currentPrice || "--" }}</span
        >
        <span class="bid-info-prefix">，加价幅度</span>
        <span class="bid-info-value">¥{{ stepPrice || "--" }}</span>
      </div>
      <div class="bid-splitline" />
      <div class="bid-rule2">
        <div class="bid-rule2-checkbox">
          <van-checkbox
            v-model="submitData.isCheckedAgreement"
            shape="square"
          />
        </div>
        <div class="bid-rule2-link">
          我已阅读并同意
          <a
            target="_blank"
            href="https://config-cdn.echoing.tech/docs/web3-auction-term-buyer.html"
          >
            《竞拍协议》
          </a>
        </div>
      </div>
      <div :class="`bond ${needMoreDeposit ? 'bond--needrecharge' : ''}`">
        <div class="bond-item" @click="handleQuestion">
          {{ depositText }}
          <img
            class="bond-item-question"
            src="https://cdn.qiandaoapp.com/interior/images/7c1316a64bbb4fd2367607b2dca53458.png"
            alt=""
          />
        </div>
        <div
          class="bond-item bond-item-recharge"
          @click="handleToRecharge"
          v-if="needMoreDeposit"
        >
          <!--          <span class="bond-item-splitline" />-->
          去充值
          <ArrowRight :style="{ marginLeft: '0.5333333333333333rem' }" type="primary" />
        </div>
      </div>
    </div>
  </div>
  <div class="submit">
    <!--      @click="handleClickBtn"-->
    <div class="submit-btn">
      <van-button
        type="primary"
        size="large"
        :disabled="disableSubmit"
        @click="handleSubmit"
      >
        确认出价
      </van-button>
    </div>
  </div>
  <deposit-recharge-popup
    v-model="isShowRechargePopup"
    :userDeposit="userDeposit"
    :depositRatio="depositRatio"
    :depositBidPrice="depositBidPrice"
    :auctionId="auctionDetailData.id"
  />
  <deposit-recharge-rules-dialog v-model="isShowRechargeRules" />
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  onMounted,
  computed,
  watch,
  inject,
} from "vue";
import {
  Button as VanButton,
  Image as VanImage,
  Checkbox as VanCheckbox,
  showFailToast,
  showSuccessToast,
  showConfirmDialog,
} from "vant";
import Stepper from "@/components/Stepper";
import TabSwitch from "@/components/TabSwitch";
import BidApi from "@/apis/bid";
import { REQUEST_OK } from "@/constant/network";
import ArrowRight from "@/components/ArrowRight/index.vue";
import DepositRechargePopup from "@/components/DepositRechargePopup/index.vue";
// import CopyTokenTips from './CopyTokenTips.vue'
import { useDeposit } from "@/hooks/useDeposit";
import RiskWarningPopup from "@/components/RiskWarningPopup/index.vue";
import DepositRechargeRulesDialog from "@/components/DepositRechargeRulesDialog/index.vue";
import WalletApi from "@/apis/wallet";

const {
  initDeposits,
  ratio: depositRatio,
  userDeposit,
  bidInfo,
} = useDeposit();

const price = ref(0);
const bidPrice = ref(0);
const initPrice = ref(0);
const isShowRechargePopup = ref(false);
const isShowRechargeRules = ref(false);
const isFirstBid = ref(false);
const minPrice = ref(0);
const stepPrice = ref(0);
const depositData = {};
const submitData = ref({
  isCheckedAgreement: true,
});
const tabs = ref([
  { name: "自动出价", type: "auto" },
  { name: "手动出价", type: "manual" },
]);
const currentSwitchTab = ref(tabs.value[0]);
// bidCount 拍卖数为0, 是起拍
// startPrice 起拍价
// currentPrice 当前价

const props = defineProps({
  // wasParticipate: {
  //   type: Boolean,
  //   required: true,
  //   default: false,
  // },
  // detailData: {
  //   type: Object,
  //   required: true,
  //   default: () => null,
  // },
  // auctionInfo: {
  //   type: Object,
  //   required: true,
  //   default: () => null,
  // },
  iAmMaxBidUser: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onBidSuccess"]);

const auctionDetailData = inject("auctionDetailData");
const detailData = inject("detailData");

const wasParticipate = computed(() => {
  return bidInfo.value?.autoBidPrice;
});

// const isLeading = computed(() => state.autoBiddingPrice >= currentPrice.value)
const isLeading = computed(() => {
  return props.iAmMaxBidUser;
});

const depositBidPrice = computed(() => {
  let tempPrice = price.value;
  if (isLeading.value) {
    tempPrice = tempPrice - bidInfo.value?.autoBidPrice;
  }

  return tempPrice;
});

const needMoreDeposit = computed(() => {
  if (
    userDeposit.value?.needPayDeposit ||
    depositBidPrice.value > userDeposit?.value?.availableBidPrice
  ) {
    return true;
  } else {
    return false;
  }
});

const freezeDeposit = computed(() => {
  return (depositBidPrice.value / depositRatio.value).toFixed(2);
});

const depositText = computed(() => {
  let content = "冻结保证金 ¥0";
  let prefixTxt = "";
  // depositData.value?.availableGiftAmount
  //   ? `(平台可垫付${depositData.value.availableGiftAmount})`
  //   : "";

  //   // 是否显示平台可垫付:  可垫付额度 >=  需要冻结的保证金 && 可用额度 < 需要冻结的保证金
  //   if (_fundAccount.extAvailableGiftAmount >= _toBeFreezingSecurityDepositAmount &&
  //     _fundAccount.extAvailableAmount < _toBeFreezingSecurityDepositAmount)
  //
  // //  平台可垫付 =  需要冻结的保证金 -  可用额度
  //     _toBeFreezingSecurityDepositAmount - _fundAccount.extAvailableAmount

  if (
    depositData.value?.availableGiftAmount >= freezeDeposit.value &&
    depositData.value?.availableAmount < freezeDeposit.value
  ) {
    prefixTxt = `(平台可垫付¥${
      freezeDeposit.value - depositData.value.availableAmount
    })`;
  }
  if (userDeposit.value) {
    if (depositBidPrice.value) {
      content = `${isLeading.value ? "需补" : "将"}冻结保证金 ¥ ${
        freezeDeposit.value
      }${prefixTxt}`;
    }
  }
  return content;
});

const disableSubmit = computed(() => {
  if (price.value < initPrice.value) {
    return true;
  } else {
    return false;
  }
});

watch(
  () => bidInfo.value,
  (newParams, oldParams) => {
    const myBid = newParams;
    const { incrementsAmount } = auctionDetailData.value;

    if (+myBid?.count > 0) {
      const tempPrice =
        (price.value > myBid.autoBidPrice ? price.value : myBid.autoBidPrice) +
        incrementsAmount;

      bidPrice.value = tempPrice;
      // initPrice.value = tempPrice;
      price.value = tempPrice;
      return;
    } else {
      price.value = auctionDetailData.value?.currentPrice;
    }
    console.log("bidInfo.value=--==-=", newParams);
  },
  {
    deep: true,
  }
);

const onClickSwitch = (data) => {
  currentSwitchTab.value = data;
};

const handleToRecharge = () => {
  isShowRechargePopup.value = true;
};

const handleQuestion = () => {
  isShowRechargeRules.value = true;
};

const handleSubmit = () => {
  if (!submitData.value.isCheckedAgreement)
    return showFailToast("请阅读并同意竞拍协议");
  const isDirect = currentSwitchTab.value.type === "manual";
  const dialogMessage = isDirect
    ? `确认使用手动出价，直接出价¥${price.value}吗？`
    : `确认使用自动出价，在¥${price.value}内自动竞价吗？`;
  showConfirmDialog({
    title: "",
    message: dialogMessage,
  })
    .then(async () => {
      const res = await BidApi.goBid({
        auctionId: auctionDetailData.value?.id,
        bidPrice: price.value,
        isDirect,
      });
      if (+res.code === 5100001) {
        showFailToast(res.message || "出价失败");
        isShowRechargePopup.value = true;
        return;
      }
      if (res.code !== REQUEST_OK) {
        return showFailToast(res.message || "出价失败");
      }

      showSuccessToast("出价成功");
      emit("onBidSuccess", res.data?.useFree);
      console.log("goBid-res", res);
    })
    .catch(() => {
      // on cancel
    });
};

const initWalletInfo = async () => {
  const res = await WalletApi.getDepositWallet();
  if (res?.code !== REQUEST_OK) {
    return showFailToast(res.message || "查询钱包失败");
  }
  const depositInfo = res?.data?.accounts.filter(
    (item) =>
      item.accountType === "Wallet" && item.amountType === "GLOBAL_DEPOSIT"
  )?.[0];
  console.log("depositInfo", depositInfo);

  depositData.value = depositInfo || {};
};

onMounted(() => {
  const { startPrice, currentPrice, incrementsAmount, bidCount, auctionId } =
    auctionDetailData.value;

  isFirstBid.value = bidCount === 0;
  stepPrice.value = incrementsAmount;

  if (isFirstBid.value) {
    if (currentPrice && incrementsAmount) {
      initPrice.value = startPrice;
      minPrice.value = startPrice;
    }
  } else {
    if (currentPrice && incrementsAmount) {
      initPrice.value = currentPrice + incrementsAmount;
      minPrice.value = currentPrice + incrementsAmount;
    }
  }

  initWalletInfo();
  initDeposits({ auctionId });
  // price.value =
});
</script>

<style lang="scss">
.bid {
  padding: 88rpx 30rpx 0 30rpx;
  &-img {
    margin: 0 auto;
    width: 316rpx;
    height: 316rpx;
    filter: drop-shadow(0 8rpx 40rpx rgba(0, 0, 0, 0.06));
    border-radius: 24rpx;
    overflow: hidden;
    &-content {
      width: 100%;
      height: 100%;
    }
  }
  &-title {
    margin-top: 24rpx;
    font-weight: 900;
    font-size: 36rpx;
    line-height: 50rpx;
    color: #000000;
    text-align: center;
  }
  &-joined-tips {
    maring-top: 16rpx;
    font-weight: 400;
    font-size: 24rpx;
    line-height: 36rpx;
    color: #000000;
    text-align: center;
  }
  &-switch {
    margin: 8rpx auto 0 auto;
  }
  &-current {
    margin-top: 8rpx;
    text-align: center;
    &-tips {
      font-weight: 400;
      font-size: 24rpx;
      line-height: 36rpx;
      color: #000000;
      &-value {
        font-weight: 900;
      }
    }
  }
  &-stepper {
    margin-top: 20rpx;
  }
  &-rule {
    margin-top: 16rpx;
    font-weight: 400;
    font-size: 24rpx;
    line-height: 36rpx;
    color: #000000;
    text-align: center;
    a {
      color: var(--primary-purple);
      font-weight: bold;
      font-size: 24rpx;
      line-height: 36rpx;
      font-weight: 400;
    }
  }
  &-info {
    margin-top: 16rpx;
    text-align: center;
    &-prefix {
      font-weight: 400;
      font-size: 24rpx;
      line-height: 36rpx;
      color: rgba(0, 0, 0, 0.5);
    }
    &-value {
      margin-left: 8rpx;
      font-weight: 900;
      font-size: 24rpx;
      line-height: 36rpx;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  &-splitline {
    margin-top: 17rpx;
    width: 690rpx;
    //height: 2rpx;
    border-bottom: 2rpx solid rgba(0, 0, 0, 0.1);
  }
  &-rule2 {
    margin-top: 25rpx;
    display: flex;
    align-items: center;
    //justify-content: center;
    &-link {
      margin-left: 8rpx;
      font-weight: 400;
      font-size: 20rpx;
      color: rgba(0, 0, 0, 0.3);
      a {
        color: var(--primary-purple);
        font-weight: bold;
        font-size: 20rpx;
        font-weight: 400;
      }
    }
  }
}
.submit {
  margin: 16rpx auto 0 auto;
  //padding-bottom: 68rpx;
  width: 100%;
  height: calc(128rpx + 68rpx);
  background: #ffffff;
  overflow: hidden;
  &-btn {
    margin: 16rpx auto 0 auto;
    width: 290rpx;
  }
}
//.top-wrp {
//  background-color: #F8F8F8;
//}

//.van-stepper__input {
//  border: 2rpx solid red;
//}

.bond {
  padding: 20rpx 20rpx;
  margin-top: 24rpx;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16rpx;
  background: #f2f0ff;
  &-item {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7c66ff;
    font-size: 24rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 36rpx; /* 150% */
    //flex: 1;
    &-question {
      position: relative;
      top: -2rpx;
      width: 24rpx;
      display: block;
      margin-left: 4rpx;
    }
    &-splitline {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      height: 16rpx;
      width: 2rpx;
      background: #7c66ff;
    }
  }
  //&-item-recharge {
  //  margin-left: 10rpx;
  //}
}

.bond--needrecharge {
  justify-content: space-between;
}

:root:root {
  --van-button-border-width: 0;
  --van-popup-background: #f8f8f8;
}
</style>

<!--v-model	min max step-->
