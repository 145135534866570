<!--
   Created by Terence on 2024/3/7 - 17:34
-->
<template>
  <div class="tokencard" @click="handleToTokenPage">
    <div class="tokencard-top">
      <div class="tokencard-top-covers" @click="handleShowCovers">
        <van-image
          fit="cover"
          class="tokencard-top-covers-img"
          :src="
            detailData.covers?.[0]?.url ||
            'https://cdn.qiandaoapp.com/admins/69ed417d33591d464f446b24807b1c86.png' +
              '!mfit_w750_h750_jpg'
          "
          alt=""
        />
        <img
          class="tokencard-top-covers-icon"
          src="https://cdn.qiandaoapp.com/ued/9c0dc70eaaccb3ee9c9a940bc5184436.png"
          alt=""
        />
      </div>
      <div class="tokencard-top-name">
        <div class="tokencard-top-name-value otext">
          {{ detailData.name }}
        </div>
        <div class="tags">
          <div v-if="detailData.type === 'SFT'" class="tags-type">
            批发类作品
          </div>
          <div class="tags-clothes">{{ detailData.artTypeDisplayName }}</div>
        </div>
      </div>
      <div style="flex-grow: 0"></div>
      <div class="tokencard-top-like" @click="handleLike">
        <img
          class="tokencard-top-like-icon"
          src="https://cdn.qiandaoapp.com/ued/06155ec80ce5ff0158969e2c41c98ae0.png"
          alt=""
        />
        <div class="tokencard-top-like-value">
          {{ detailData.likeCount }}
        </div>
      </div>
    </div>

    <div class="tokencard-bottom otext2">
      {{ detailData.description }}
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import { Image as VanImage, showImagePreview } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();

const emit = defineEmits(["onInvokeApp"]);

const detailData = inject("detailData");
const tokenId = inject("tokenId");

const handleToTokenPage = () => {
  router.push(`/token-detail?tokenId=${tokenId.value}`);
};

const handleLike = (e) => {
  e.stopPropagation();
  emit("onInvokeApp");
};

const handleShowCovers = (e) => {
  e.stopPropagation();
  const tempImgs = detailData.value.covers.map(
    (item) => item.url + "!mfit_w750_h750_jpg"
  );
  showImagePreview(
    tempImgs?.length
      ? tempImgs
      : [
          "https://cdn.qiandaoapp.com/admins/69ed417d33591d464f446b24807b1c86.png",
        ]
  );
};
</script>

<style lang="scss" scoped>
// TODO 各preview地方 静止下载图片
.tokencard {
  position: relative;
  &-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //background-color: skyblue;
    &-covers {
      position: relative;
      margin-right: 8rpx;
      width: 100rpx;
      height: 100rpx;
      box-shadow: 8rpx 8rpx 16rpx rgba(0, 0, 0, 0.06);
      border-radius: 24rpx;
      overflow: hidden;
      &-img {
        display: block;
      }
      &-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40rpx;
        height: 40rpx;
      }
    }
    &-name {
      flex: 1; /* 让这两个元素都可以伸缩，占据可用空间 */
      min-width: 0; /* 防止它们溢出父容器 */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &-value {
        max-width: 100%;
        overflow: hidden; /* 确保内容溢出时不会显示 */
        text-overflow: ellipsis; /* 当文本溢出时，显示省略号(...)来表示还有更多内容 */
        white-space: nowrap;
        color: var(--v-10-main-neural-07, rgba(0, 0, 0, 0.64));
        font-size: 32rpx;
        font-weight: 500;
        line-height: 48rpx; /* 150% */
      }
      .tags {
        margin-top: 8rpx;
        display: flex;
        align-items: center;
        &-type {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4rpx 8rpx;
          border-radius: 4rpx;
          background: var(---color_third, #7247dc);
          color: var(--dark-text-primary, #fff);
          font-size: 24rpx;
          font-weight: 500;
          line-height: 36rpx; /* 150% */
        }
        &-clothes {
          margin-left: 8rpx;
          padding: 0 8rpx;
          height: 44rpx;
          box-sizing: border-box;
          border: 2rpx solid #7247dc;
          border-radius: 4rpx;
          line-height: 44rpx;
          font-weight: bold;
          color: #7247dc;
        }
      }
    }
    &-like {
      margin-left: auto;
      flex: 0 0 92rpx;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 92rpx;
      height: 92rpx;
      border-radius: 100%;
      box-shadow: 0px 8rpx 40rpx rgba(0, 0, 0, 0.06);
      background-color: #fff;
      &-icon {
        width: 40rpx;
        height: 40rpx;
      }
      &-value {
        margin-top: 4rpx;
        font-weight: 900;
        font-size: 20rpx;
        line-height: 22rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &-bottom {
    margin-top: 8rpx;
    overflow: hidden;
    color: var(--v-10-main-neural-06, rgba(0, 0, 0, 0.4));
    font-size: 24rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 36rpx; /* 150% */
  }
}
</style>
