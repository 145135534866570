<template>
  <div class="stepper">
    <button
      :class="`stepper-left ${inputValue <= min && 'stepper-left--disabled'}`"
      @click="handleMinus"
    />
    <span v-if="contentType === 'text'" class="stepper-content">{{
      inputValue
    }}</span>
    <input v-else type="tel" v-model="inputValue" />
    <button
      :class="`stepper-right ${inputValue >= max && 'stepper-right--disabled'}`"
      @click="handleAdd"
    />
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted } from "vue";

const inputValue = ref(0);
// const min = ref(8)
// const max = ref(13)
// const step = ref(1)
const props = defineProps({
  modelValue: {},
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 0,
  },
  contentType: {
    type: String,
    default: "input",
  },
  step: {
    type: Number,
    default: 1,
  },
  initValue: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits(["update:modelValue"]);

watch(inputValue, (value) => {
  emit("update:modelValue", value);
});

watch(
  () => props.initValue,
  (value) => {
    inputValue.value = value;
    emit("update:modelValue", value);
  },
  {
    immediate: true,
  }
);

const handleMinus = () => {
  const { step, min } = props;
  const tempValue = +inputValue.value - step;
  if (tempValue < min) {
    return;
  }
  inputValue.value = tempValue;
};

const handleAdd = () => {
  const { step, max } = props;
  const tempValue = +inputValue.value + step;
  if (tempValue > max) {
    return;
  }
  inputValue.value = tempValue;
};

onMounted(() => {
  inputValue.value = props.initValue || props.modelValue;
  // inputValue.value = props.modelValue
});
</script>

<style lang="scss">
:root {
  --lj-stepper-input-width: 200rpx;
  --lj-stepper-input-height: 68rpx;
}

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //background: var(--primary-red)''
  input {
    transition: all 0.1s;
    width: var(--lj-stepper-input-width);
    height: var(--lj-stepper-input-height);
    border: 2rpx solid rgba(0, 0, 0, 0.1);
    border-radius: 16rpx;
    text-align: center;
    font-family: "Roboto";
    font-weight: 900;
    font-size: 40rpx;
    color: #000000;
  }
  input:focus {
    border: 2rpx solid var(--primary-purple);
  }
  &-content {
    text-align: center;
    font-family: "Roboto";
    font-weight: 900;
    font-size: 40rpx;
    color: #000000;
  }
  &-left,
  &-right {
    transition: all 0.1s;
    position: relative;
    width: 44rpx;
    height: 44rpx;
    border: 0;
    border-radius: 22rpx;
    background: var(--primary-linear-gradient);
    &--disabled {
      opacity: 0.3;
    }
  }
  &-left {
    margin-right: 32rpx;
  }
  &-right {
    margin-left: 32rpx;
  }

  &-left::before,
  &-right::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 50%;
    height: 6rpx;
    background-color: #fff;
  }

  &-right::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    content: "";
    width: 50%;
    height: 6rpx;
    background-color: #fff;
  }

  button:active {
    //transition: all 1s;
    box-shadow: inset 0 0 4rpx 4rpx rgb(78, 78, 78);
  }
}
</style>
